import React from 'react';
import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';
import Grid from 'styled-components-grid';

import Logo from '../Logo';
import FooterColumn from './FooterColumn';

import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import {
  COLOR_GREY_DARK,
  COLOR_WHITE,
  FONT_SIZE_XXXSMALL,
  GRID_COLUMNS,
} from '../../styles/Variables';
import { FooterPropsType } from './types';

const FooterWrapper = styled.footer`
  padding: 3rem 3rem 5rem;
  background-color: ${COLOR_GREY_DARK};

  ${breakpoint('xs', 'md')`
    padding: 3rem 2rem 5rem;
  `}

  ${breakpoint('xs', 'sm')`
    text-align: center;
  `}
`;

const FooterLeftWrapper = styled(Grid.Unit)`
  display: flex;
  align-items: flex-start;

  ${breakpoint('xs', 'sm')`
    justify-content: center;
  `}
`;

const FooterRightWrapper = styled(Grid.Unit)`
  ${breakpoint('sm', 'lg')`
    margin-left: auto;
    padding-top: 4rem;
  `}
`;

const FooterWrapperInner = styled(Grid)`
  ${breakpoint('xs', 'sm')`
    display: block;
  `}
`;

const FooterCopyrights = styled.p`
  margin-left: 4.5rem;
  margin-top: 1rem;
  position: relative;
  font-size: ${FONT_SIZE_XXXSMALL};
  font-weight: 300;

  ${breakpoint('xs', 'sm')`
    display: none;
  `}

  &:before {
    content: '';
    display: block;
    height: 100%;
    width: 1px;
    position: absolute;
    top: 0;
    left: -1.8rem;
    background-color: ${COLOR_WHITE};

    ${breakpoint('xs', 'sm')`
      display: none;
    `}
  }
`;

// TODO: utilities, moreover they are global probably
const contactComponents = {
  mail: ({ link }) => link.replace('mailto:', ''),
};

const socialMediaComponents = {
  facebook: () => 'Facebook',
  instagram: () => 'Instagram',
  linkedin: () => 'Linkedin',
};

const videoMediaComponents = {
  vimeo: () => 'Vimeo',
  youtube: () => 'YouTube',
};

const Footer: React.SFC<FooterPropsType> = ({
  socialItems,
  footerStrings: {
    footerCopyrights,
    footerFirstColumnTitle,
    footerSecondColumnTitle,
    footerThirdColumnTItle,
  },
}: FooterPropsType) => (
  <FooterWrapper>
    <Grid>
      <FooterLeftWrapper
        size={{ xs: 1, lg: 5 / GRID_COLUMNS, xl: 6 / GRID_COLUMNS }}
      >
        <Logo />
        <FooterCopyrights>
          {documentToReactComponents(footerCopyrights.json)}
        </FooterCopyrights>
      </FooterLeftWrapper>
      <FooterRightWrapper
        size={{
          xs: 1,
          md: 9 / GRID_COLUMNS,
          lg: 7 / GRID_COLUMNS,
          xl: 6 / GRID_COLUMNS,
        }}
      >
        <FooterWrapperInner>
          <FooterColumn
            components={contactComponents}
            items={socialItems}
            title={footerFirstColumnTitle}
          />
          <FooterColumn
            components={socialMediaComponents}
            items={socialItems}
            title={footerSecondColumnTitle}
          />
          <FooterColumn
            components={videoMediaComponents}
            items={socialItems}
            title={footerThirdColumnTItle}
          />
        </FooterWrapperInner>
      </FooterRightWrapper>
    </Grid>
  </FooterWrapper>
);

export default Footer;
