import Img from 'gatsby-image';
import map from 'lodash/map';
import React from 'react';
import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';

import { EASE_IN_OUT_CUBIC } from '../../styles/Variables';
import { NavigationMenuImagesPropsType } from './types';

const NavigationMenuImageWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: 360px;
  height: 100%;
  transition: 0.2s ${EASE_IN_OUT_CUBIC};
  opacity: ${({ active }: { active: boolean }) => (active ? 1 : 0)};
`;

const NavigationMenuImagesWrapper = styled.div`
  width: 50%;
  height: 100%;
  position: relative;

  ${breakpoint('xs', 'xl')`
    display: none;
  `}
`;

const NavigationMenuImage = styled(Img)`
  position: absolute;
  top: 50%;
  left: 50%;
  height: 100%;
  transform: translate(-50%, -50%);

  img {
    min-width: 100%;
    min-height: 100%;
  }
`;

const NavigationMenuImages: React.SFC<NavigationMenuImagesPropsType> = ({
  images,
  activeIndex,
}: NavigationMenuImagesPropsType) => (
  <NavigationMenuImagesWrapper>
    {map(images, ({ fluid }, i) => (
      <NavigationMenuImageWrapper active={i === activeIndex} key={i}>
        <NavigationMenuImage
          // imgStyle={{ height: '100%' }}
          objectFit="cover"
          objectPosition="50% 50%"
          fluid={fluid}
          loading="lazy"
        />
      </NavigationMenuImageWrapper>
    ))}
  </NavigationMenuImagesWrapper>
);

export default NavigationMenuImages;
