import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { MARKS } from '@contentful/rich-text-types';
import Img from 'gatsby-image';
import React from 'react';
import Fade from 'react-reveal/Fade';
import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';
import Grid from 'styled-components-grid';

import MenuArrowSvg from '../../assets/img/svg/menu-arrow.svg';

import {
  COLOR_GREEN,
  COLOR_MAGENTA,
  GRID_COLUMNS,
} from '../../styles/Variables';

import SectionTitle from '../Base/SectionTitle';

import { ConsultingInfoPropsType } from './types';

const ConsultingInfoRight = styled(Grid.Unit)`
  margin-top: 30rem;
  padding-right: calc(1 / 12 * 100%);
  position: relative;

  ${breakpoint('xs', 'md')`
    margin-top: 4rem;
    padding-right: 0;
  `}

  & p {
    padding-bottom: 3rem;
    font-weight: 300;
  }
`;

const ConsultingInfoLeft = styled(Grid.Unit)`
  padding-left: calc(4 / 36 * 100%);
  padding-top: 9rem;
  position: relative;

  ${breakpoint('xs', 'lg')`
    padding-left: 0;
  `}

  ${breakpoint('xs', 'md')`
    padding-top: 11rem;
  `}
`;

const ImgWrapper = styled.div`
  width: 60%;
  max-width: 380px;
  position: absolute;
  right: -17%;
  top: 0;

  ${breakpoint('xs', 'lg')`
    width: 25rem;
  `}

  ${breakpoint('xs', 'md')`
    right: auto;
    left: 30%;
  `}
`;

const BoldText = styled.span`
  font-weight: 700;
`;

const ColoredText = styled.span`
  color: ${COLOR_GREEN};
`;

const ArrowWrapper = styled(MenuArrowSvg)`
  position: absolute;
  top: -4.5rem;
  left: -4.5rem;
  transform: 'rotate(180deg)';

  ${breakpoint('md', 'xl')`
    display: none;
  `}

  ${breakpoint('xs', 'md')`
    position: static;
    margin-bottom: 3rem;
  `}
`;

const Color = ({ children }: { children: string }) => (
  <ColoredText>{children}</ColoredText>
);
const Bold = ({ children }: { children: string }) => (
  <BoldText>{children}</BoldText>
);
const options = {
  renderMark: {
    [MARKS.ITALIC]: text => <Color>{text}</Color>,
    [MARKS.BOLD]: text => <Bold>{text}</Bold>,
  },
};

const ConsultingInfo: React.SFC<ConsultingInfoPropsType> = ({
  description,
  sectionTitle,
  image,
}: ConsultingInfoPropsType) => (
  <Grid wrap={{ xs: true, md: false }}>
    <ConsultingInfoLeft
      size={{ xs: 1, md: 5 / GRID_COLUMNS, lg: 6 / GRID_COLUMNS }}
    >
      <Fade left={true} distance="50px">
        <SectionTitle
          sectionTitle={sectionTitle}
          options={{
            letters: [
              { indexes: [1, 2], color: `${COLOR_GREEN}` },
              { indexes: [3, 4], color: `${COLOR_MAGENTA}` },
            ],
            breakLineIndex: 5,
          }}
        />
      </Fade>

      <ImgWrapper>
        <Fade left={true} distance="10px" duration={3000} delay={500}>
          <Img fluid={image.fluid} />
        </Fade>
      </ImgWrapper>
    </ConsultingInfoLeft>
    <ConsultingInfoRight
      size={{ xs: 1, md: 7 / GRID_COLUMNS, lg: 6 / GRID_COLUMNS }}
    >
      <Fade delay={500}>
        <ArrowWrapper textAnchor="end" preserveAspectRatio="xMaxYMin meet" />
      </Fade>

      <Fade>{documentToReactComponents(description.json, options)}</Fade>
    </ConsultingInfoRight>
  </Grid>
);

export default ConsultingInfo;
