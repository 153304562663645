import map from 'lodash/map';
import React from 'react';
import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';
import Grid from 'styled-components-grid';
import { FooterColumnPropsType } from './types';

import {
  COLOR_GREY_LIGHT,
  COLOR_WHITE,
  EASE_IN_OUT_CUBIC,
  FONT_SIZE_XSMALL,
  GRID_COLUMNS,
} from '../../styles/Variables';

const FooterColumnWrapper = styled(Grid.Unit)`
  padding-left: calc(1 / 12 * 100%);

  ${breakpoint('sm', 'md')`
    padding-left: 2rem;
  `}

  ${breakpoint('xs', 'sm')`
    padding: 3rem 0 4rem;
  `}

  /* TODO: why not styled-components?  */
  & a {
    font-weight: 300;
  }
`;

const FooterSocialHeader = styled.h5`
  margin: 1rem 0 2rem;
  position: relative;
  line-height: 1;

  ${breakpoint('xs', 'md')`
    display: inline-block;
    margin: 1rem auto 2rem;
  `}

  &:before {
    content: '';
    display: block;
    height: 100%;
    width: 1px;
    position: absolute;
    top: 0;
    left: -1.5rem;
    background-color: ${COLOR_WHITE};
  }
`;

const FooterSocialItemsLink = styled.a`
  display: block;
  color: ${COLOR_WHITE};
  transition: 0.2s ${EASE_IN_OUT_CUBIC};
  font-size: ${FONT_SIZE_XSMALL};
  svg,
  g,
  path {
    transition: 0.2s ${EASE_IN_OUT_CUBIC};
    fill: ${COLOR_WHITE};
    fill-opacity: 1;
  }

  :hover {
    color: ${COLOR_GREY_LIGHT};
    svg,
    g,
    path {
      fill: ${COLOR_GREY_LIGHT};
    }
  }
`;

const FooterColumn: React.SFC<FooterColumnPropsType> = ({
  components,
  items,
  title,
}: FooterColumnPropsType) => (
  <FooterColumnWrapper size={{ xs: 1, sm: 4 / GRID_COLUMNS }}>
    <FooterSocialHeader>{title}</FooterSocialHeader>
    {map(items, ({ linkId, link }, i) => {
      const Component = components[linkId];
      if (Component) {
        return (
          <FooterSocialItemsLink key={i} target="_blank" href={link}>
            <Component link={link} />
          </FooterSocialItemsLink>
        );
      }
    })}
  </FooterColumnWrapper>
);

export default FooterColumn;
