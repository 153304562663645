import { graphql, useStaticQuery } from 'gatsby';
import get from 'lodash/get';
import React from 'react';
import { Link } from 'react-scroll';
import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';

import ContactBottom from './ContactBottom';
import ContactTop from './ContactTop';

import kebabCase from 'lodash/kebabCase';
import { withLanguageFilter } from '../../enhancers/withLanguage';
import { COLOR_WHITE, FONT_SIZE_XSMALL } from '../../styles/Variables';
import { ContactPropsType } from './types';

const ContactWrapper = styled.section`
  padding: 12rem 3rem;
  position: relative;

  ${breakpoint('xs', 'md')`
    padding: 13rem 2rem;
  `}
`;

const ScrollTopButton = styled(Link)`
  position: absolute;
  right: calc(1 / 12 * 100%);
  bottom: 4rem;
  font-size: ${FONT_SIZE_XSMALL};
  color: ${COLOR_WHITE};
  opacity: 0.6;
  cursor: pointer;
  transition: opacity 0.2s ease-in;

  ${breakpoint('xs', 'sm')`
    right: 50%;
    transform: translateX(50%);
  `}

  &:hover {
    opacity: 1;
  }
`;

const Contact: React.SFC<ContactPropsType> = ({
  nodeLocale,
}: ContactPropsType) => {
  const data = useStaticQuery(contactQuery);
  const contactData = withLanguageFilter({ nodeLocale, data });
  const navTitle = get(contactData, 'contentfulContact.navigation.title');

  return (
    <ContactWrapper id={kebabCase(navTitle)}>
      <ContactTop
        socialItems={get(contactData, 'contentfulSocials')}
        contactData={get(contactData, 'contentfulContact')}
      />

      <ContactBottom contactData={get(contactData, 'contentfulContact')} />

      <ScrollTopButton
        to="splash"
        smooth="easeOutCubic"
        offset={-70}
        duration={1000}
      >
        Scroll up
      </ScrollTopButton>
    </ContactWrapper>
  );
};

export default Contact;

export const contactQuery = graphql`
  query Contact {
    allContentfulContact {
      edges {
        node {
          node_locale
          navigation {
            title
          }
          buttonLink
          buttonText
          lead {
            lead
          }
          title
          subtitle
          sectionTitle
        }
      }
    }
    allContentfulSocials {
      edges {
        node {
          node_locale
          linkId
          link
        }
      }
    }
  }
`;
