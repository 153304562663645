import { graphql, StaticQuery } from 'gatsby';
import get from 'lodash/get';
import React from 'react';
import Fade from 'react-reveal/Fade';
import { Link } from 'react-scroll';
import styled, { css, keyframes } from 'styled-components';
import breakpoint from 'styled-components-breakpoint';

import { kebabCase } from 'lodash';
import SplashGlitchLeft from '../../assets/img/svg/splash-glitch-0.svg';
import SplashGlitchRight from '../../assets/img/svg/splash-glitch-1.svg';
import SplashGlitchMobileLeft from '../../assets/img/svg/splash-glitch-mobile-0.svg';
import SplashGlitchMobileRight from '../../assets/img/svg/splash-glitch-mobile-1.svg';
import { withLanguageFilter } from '../../enhancers/withLanguage';
import {
  COLOR_WHITE,
  EASE_IN_OUT_CUBIC,
  FONT_SIZE_XSMALL,
} from '../../styles/Variables';
import SplashSlider from './SplashSlider';

const move1 = keyframes`
  0% {
    transform: translateX(0);
  }

  50% {
    transform: translateX(-20px);
  }

  100% {
    transform: translateX(0px);
  }
`;

const move2 = keyframes`
  0% {
    transform: translateX(0);
  }

  50% {
    transform: translateX(40px);
  }

  100% {
    transform: translateX(0px);
  }
`;

const SplashWrapper = styled.div`
  position: relative;
  overflow: hidden;
  height: 100vh;
  width: 100%;
`;

const SplashScroll = styled(Link)`
  position: absolute;
  z-index: 10;
  bottom: 50px;
  font-size: ${FONT_SIZE_XSMALL};
  will-change: opacity;
  opacity: 0.6;
  color: ${COLOR_WHITE};
  transition: opacity 0.3s ${EASE_IN_OUT_CUBIC};
  left: 50%;
  transform: translateX(-50%);

  :hover {
    cursor: pointer;
    opacity: 1;
  }

  ${breakpoint('xs', 'sm')`
    z-index: 5;
    bottom: 2rem;
    opacity: 1;
  `}
`;

const StyledSplashGlitch = css`
  z-index: 2;
  position: absolute;
  transform: translateY(-50%);
`;

const StyledSplashGlitchLeft = styled(SplashGlitchLeft)`
  ${StyledSplashGlitch}
  top: 5%;
  left: 0;
  display: none;
  ${breakpoint('lg')`
    display: block;
    animation: ${move1} 10s ease-in infinite;  
  `}
`;

const StyledSplashGlitchRight = styled(SplashGlitchRight)`
  ${StyledSplashGlitch}
  bottom: 10%;
  right: 0;
  display: none;
  ${breakpoint('lg')`
    display: block;  
    animation: ${move2} 11s ease-in infinite;  
  `}
`;

const StyledSplashGlitchMobileLeft = styled(SplashGlitchMobileLeft)`
  ${StyledSplashGlitch}
  top: 38%;
  left: 0;
  display: block;
  ${breakpoint('lg')`
    display: none;
  `}
`;

const StyledSplashGlitchMobileRight = styled(SplashGlitchMobileRight)`
  ${StyledSplashGlitch}
  bottom: 10%;
  right: 0;
  display: block;
  ${breakpoint('lg')`
    display: none;  
  `}
`;

const Splash = ({ id, sliderContent, scrollDownText, scrollDownLink }) => (
  <SplashWrapper id={id}>
    <StyledSplashGlitchLeft />
    <StyledSplashGlitchMobileLeft />
    <StyledSplashGlitchRight />
    <StyledSplashGlitchMobileRight />
    <SplashSlider splashContent={sliderContent} />
    <SplashScroll
      to={scrollDownLink}
      smooth="easeOutCubic"
      offset={-70}
      duration={500}
    >
      <Fade delay={1200} top={true} duration={1000}>
        {scrollDownText}
      </Fade>
    </SplashScroll>
  </SplashWrapper>
);

export default props => (
  <StaticQuery
    query={graphql`
      query SplashQuery {
        allContentfulSplash {
          edges {
            node {
              node_locale
              splashContent {
                title
                videoFile {
                  file {
                    url
                  }
                }
                videoPlaceholder {
                  fluid(maxWidth: 860, quality: 100) {
                    ...GatsbyContentfulFluid_withWebp
                  }
                }
              }
              navigation {
                title
              }
            }
          }
        }
        allContentfulGeneralStrings {
          edges {
            node {
              splashScrollButton
              node_locale
            }
          }
        }
        allContentfulAboutUs {
          edges {
            node {
              node_locale
              navigation {
                title
              }
            }
          }
        }
      }
    `}
    render={data => {
      const filteredData = withLanguageFilter({ ...props, data });
      const navTitle = get(filteredData, 'contentfulSplash.navigation.title');

      return (
        <Splash
          id={kebabCase(navTitle)}
          sliderContent={get(filteredData, 'contentfulSplash.splashContent')}
          scrollDownLink={kebabCase(
            get(filteredData, 'contentfulAboutUs.navigation.title')
          )}
          scrollDownText={get(
            filteredData,
            'contentfulGeneralStrings.splashScrollButton'
          )}
        />
      );
    }}
  />
);
