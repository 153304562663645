import React from 'react';
import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';

import throttle from 'lodash/throttle';
import { COLOR_BLACK, LAYOUT_MAX_WIDTH } from '../../styles/Variables';

const HeaderWrapper = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding: 25px 30px;
  display: flex;
  align-items: center;
  z-index: 10;
  background-color: ${({ scrolled }: { scrolled: boolean }) =>
    scrolled ? `${COLOR_BLACK}` : 'transparent'};
  transition: background-color 0.3s ease-in-out;

  ${breakpoint('xs', 'sm')`
    padding: 15px 20px;
  `}
`;

const HeaderContent = styled.div`
  width: 100%;
  max-width: ${LAYOUT_MAX_WIDTH};
  margin: 0 auto;
  display: flex;
  justify-content: space-between;

  ${breakpoint('xs', 'sm')`
    align-items: center
  `}
`;

interface HeaderPropsType {
  children: React.ReactNode;
}

class Header extends React.PureComponent<HeaderPropsType> {
  public state = {
    isScrolled: false,
  };
  public isBrowser = typeof window !== `undefined`;
  public getScrollPosition = () => (!this.isBrowser ? 0 : window.scrollY);

  public handleScroll = () =>
    this.getScrollPosition() > 100
      ? this.setState({ isScrolled: true })
      : this.setState({ isScrolled: false });

  public componentDidMount() {
    window.addEventListener('scroll', throttle(this.handleScroll, 200));
  }

  public componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  public render() {
    return (
      <HeaderWrapper scrolled={this.state.isScrolled}>
        <HeaderContent>{this.props.children}</HeaderContent>
      </HeaderWrapper>
    );
  }
}

export default Header;
