import map from 'lodash/map';
import React, { useState } from 'react';
import { createPortal } from 'react-dom';
import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';
import Grid from 'styled-components-grid';

import CloseSvg from '../../assets/img/svg/close.svg';
import ModalArrowSvg from '../../assets/img/svg/modal-arrow.svg';
import PlayButton from '../../assets/img/svg/play.svg';

import {
  COLOR_BLACK,
  COLOR_BLUE,
  EASE_IN_OUT_CUBIC,
  FONT_SIZE_SMALL,
  FONT_SIZE_XLARGE,
  FONT_SIZE_XSMALL,
  GRID_COLUMNS,
} from '../../styles/Variables';
import Button from '../Base/Button';
import { WorksModalPropsType } from './types';

const ModalBackground = styled.div`
  position: fixed;
  overflow: auto;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 2rem 3rem 7rem;
  background-color: rgba(0, 0, 0);
  opacity: ${({ open }) => (open ? 1 : 0)};
  transform: ${({ open }) => (open ? 'translateX(0)' : 'translateX(20%)')};
  pointer-events: ${({ open }) => (open ? 'auto' : 'none')};
  transition: 0.5s;
  z-index: 100;
  background-color: ${COLOR_BLACK};
  transition: opacity 0.3s ease-in-out, transform 0.3s ${EASE_IN_OUT_CUBIC};

  ${breakpoint('xs', 'lg')`
    padding: 2rem 0 5rem;
  `}
`;

const ModalWrapper = styled.div`
  transform: ${({ itemChanging }) =>
    itemChanging ? 'translateX(-5rem)' : 'translateX(0)'};
  opacity: ${({ itemChanging }) => (itemChanging ? '0' : '1')};
  transition: all 0.3s ${EASE_IN_OUT_CUBIC};
`;

const ModalTop = styled.div`
  position: relative;
  padding-top: 2rem;
  text-align: center;

  ${breakpoint('xs', 'lg')`
    margin: 0 2rem;
  `}

  ${breakpoint('xs', 'md')`
    padding-top: 0.5rem;
  `}

  svg {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1;
    cursor: pointer;
  }
`;

const ImgWrapper = styled.img`
  ${breakpoint('xs', 'md')`
    max-width: 100px;
  `}
`;

const ModalTitle = styled.h2`
  padding: 3rem calc(1 / 12 * 100%) 1rem;
  font-size: ${FONT_SIZE_XLARGE};
  font-weight: 300;

  ${breakpoint('md', 'lg')`
    padding: 3rem;
  `}

  ${breakpoint('xs', 'md')`
    padding: 3rem 2rem 2rem;
    font-size: 32px;
  `}
`;

const ModalContent = styled(Grid)`
  margin: 0 calc(1 / 12 * 100%);

  ${breakpoint('md', 'lg')`
    margin: 0 3rem;
  `}

  ${breakpoint('xs', 'md')`
    margin: 0 2rem;
  `}
`;

const BackButton = styled(Button)`
  margin-top: 4rem;
`;

const ModalContentList = styled(Grid.Unit)`
  padding-left: calc(2 / 12 * 100%);

  ${breakpoint('xs', 'md')`
    padding-left: 0;
  `}
`;

const ContentTitle = styled.h4`
  padding-top: 3rem;
  padding-bottom: 2rem;
  font-size: ${FONT_SIZE_XSMALL};
`;

const ContentDescription = styled.p`
  padding-bottom: 2rem;
  font-size: ${FONT_SIZE_SMALL};
  font-weight: 300;
`;

const ListItem = styled.li`
  padding-bottom: 1rem;
  font-size: ${FONT_SIZE_SMALL};
  font-weight: 300;
`;

const ModalVideoWrapper = styled.div`
  position: relative;
`;

const ModalVideo = styled.div`
  margin: 2rem calc(1 / 12 * 100%);
  position: relative;

  ::after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.2);
    opacity: ${({ isPlaying }) => (isPlaying ? 0 : 1)};
    transition: opacity 0.3s ease-in-out;
    pointer-events: none;
  }

  ${breakpoint('md', 'lg')`
    margin: 2rem 3rem;
  `}

  ${breakpoint('xs', 'md')`
    margin: 3rem 0 2rem;
  `}

  ::before {
    content: '';
    display: block;
    padding-top: 65%;
  }
`;

const PlayButtonWrapper = styled(PlayButton)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
  width: 124px;
  height: 125px;

  ${breakpoint('xs', 'md')`
    height: 92px;
    width: 91px;
  `}
`;

const ModalArrowSvgWrapper = styled(ModalArrowSvg)`
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;

  ${breakpoint('xs', 'lg')`
    top: auto;
    transform: none;
  `}

  ${breakpoint('md', 'lg')`
    bottom: 0;
    right: 4rem;
    top: auto;
    transform: none;
  `}

  ${breakpoint('xs', 'md')`
    bottom: -1.5rem;
    right: -0.5rem;
  `}
`;

const VideoWrapper = styled.video`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

const LinkWrapper = styled.p`
  padding: 2rem 0 5rem;
`;

const DescriptionLink = styled.a`
  margin-left: 1rem;
  font-size: ${FONT_SIZE_XSMALL};
  color: ${COLOR_BLUE};
`;

function canUseDOM() {
  return !!(
    typeof window !== 'undefined' &&
    window.document &&
    window.document.createElement
  );
}

const Modal: React.SFC<WorksModalPropsType> = ({
  open,
  toggleModal,
  item,
  modalStrings,
  changeItemHandler,
}: WorksModalPropsType) => {
  const videoRef = React.createRef();
  const [isPlaying, togglePlay] = useState(false);
  const [itemChanging, changeItem] = useState(false);

  const playVideo = () => {
    videoRef.current.play();
    togglePlay(!isPlaying);
  };

  const pauseVideo = () => {
    if (isPlaying) {
      videoRef.current.pause();
      togglePlay(!isPlaying);
    }
  };

  const moveToBegin = () => {
    videoRef.current.currentTime = 0;
    togglePlay(!isPlaying);
  };

  const changeItemAnimation = () => {
    changeItem(true);
    togglePlay(false);
    // TODO: change to promise ASAP, dumb engineer
    setTimeout(changeItemHandler, 400);
    setTimeout(() => changeItem(false), 400);
  };

  const closeModal = () => {
    pauseVideo();
    toggleModal(!open);
    document.body.style.height = 'auto';
    document.body.style.overflow = 'auto';
  };

  if (!canUseDOM()) {
    return null;
  }

  return createPortal(
    <ModalBackground open={open}>
      {item && (
        <ModalWrapper itemChanging={itemChanging}>
          <ModalTop>
            <ImgWrapper src={item.logo.fluid.src} />
            <CloseSvg onClick={closeModal} open={open} />
          </ModalTop>

          <ModalVideoWrapper>
            <ModalVideo isPlaying={isPlaying}>
              <VideoWrapper
                key={item.id}
                width="100%"
                height="100%"
                ref={videoRef}
                onClick={pauseVideo}
                onEnded={moveToBegin}
              >
                <source src={item.video.file.url} type="video/mp4" />
              </VideoWrapper>
            </ModalVideo>
            {!isPlaying && <PlayButtonWrapper onClick={playVideo} />}
            <ModalArrowSvgWrapper onClick={changeItemAnimation} />
          </ModalVideoWrapper>

          <ModalTitle>{item.title}</ModalTitle>

          <ModalContent>
            <Grid.Unit size={{ xs: 1, md: 6 / GRID_COLUMNS }}>
              <ContentTitle>{item.clientHeading}</ContentTitle>
              <ContentDescription>{item.clientText}</ContentDescription>
              <ContentTitle>{item.descriptionHeading}</ContentTitle>
              <ContentDescription>
                {item.descriptionText.descriptionText}
              </ContentDescription>
            </Grid.Unit>
            <ModalContentList size={{ xs: 1, md: 6 / GRID_COLUMNS }}>
              <ContentTitle>{item.listTitle}</ContentTitle>
              <ul>
                {map(item.list, (listItem, i) => (
                  <ListItem key={i}>{listItem}</ListItem>
                ))}
              </ul>
            </ModalContentList>
            <Grid.Unit size={1}>
              <LinkWrapper>
                {modalStrings.worksModalButton}
                <DescriptionLink target="_blank" href={item.linkUrl}>
                  {item.linkName}
                </DescriptionLink>
              </LinkWrapper>

              <BackButton
                reversed={true}
                modalHandler={() => toggleModal(!open)}
                buttonText={modalStrings.worksModalBackButton}
              />
            </Grid.Unit>
          </ModalContent>
        </ModalWrapper>
      )}
    </ModalBackground>,
    document.body
  );
};

export default Modal;
