import { graphql, useStaticQuery } from 'gatsby';
import get from 'lodash/get';
import React from 'react';
import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';

import kebabCase from 'lodash/kebabCase';
import { withLanguageFilter } from '../../enhancers/withLanguage';
import FeedBack from '../Feedback/Feedback';
import ConsultingInfo from './ConsultingInfo';
import { ConsultingPropsType } from './types';

const ConsultingWrapper = styled.section`
  padding: 6rem 3rem 20rem;

  ${breakpoint('xs', 'md')`
    padding: 3rem 2rem 2rem;
  `}
`;

const Consulting: React.SFC<ConsultingPropsType> = ({
  nodeLocale,
}: ConsultingPropsType) => {
  const data = useStaticQuery(consultingQuery);
  const consultingData = withLanguageFilter({ nodeLocale, data });
  const navTitle = get(consultingData, 'contentfulConsulting.navigation.title');

  return (
    <ConsultingWrapper id={kebabCase(navTitle)}>
      <ConsultingInfo {...get(consultingData, 'contentfulConsulting')} />
      <FeedBack feedbackList={get(consultingData, 'contentfulFeedback')} />
    </ConsultingWrapper>
  );
};

export default Consulting;

export const consultingQuery = graphql`
  query Consulting {
    allContentfulConsulting {
      edges {
        node {
          navigation {
            title
          }
          node_locale
          description {
            json
          }
          sectionTitle
          image {
            fluid(maxWidth: 380, quality: 100) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
        }
      }
    }
    allContentfulFeedback {
      edges {
        node {
          node_locale
          author
          company
          description {
            description
          }
          logo {
            fluid(maxWidth: 380, quality: 100) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
        }
      }
    }
  }
`;
