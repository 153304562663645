import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS, MARKS } from '@contentful/rich-text-types';
import BackgroundImage from 'gatsby-background-image';
import get from 'lodash/get';
import React from 'react';
import Fade from 'react-reveal/Fade';
import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';
import Grid from 'styled-components-grid';

import SectionTitle from '../Base/SectionTitle';
import { Glitch } from './AboutUsItemEven';
import AboutUsItemPerson from './AboutUsItemPerson';

import {
  COLOR_GREEN,
  COLOR_MAGENTA,
  FONT_SIZE_LARGE,
  FONT_SIZE_MEDIUM,
  GRID_COLUMNS,
  LAYOUT_MAX_WIDTH,
} from '../../styles/Variables';
import { AboutUsItemPropsType } from './types';

const AboutItemWrapper = styled.div`
  max-width: ${LAYOUT_MAX_WIDTH};
  margin: 0 auto;
  padding: 4.5rem 3rem 0;

  ${breakpoint('md', 'lg')`
    padding-top: 4.5rem 2rem 0;
  `}

  ${breakpoint('xs', 'md')`
    padding: 4rem 0;
  `}
`;

const AboutItemRight = styled.div`
  padding-right: calc(2 / 7 * 100%);
  padding-left: calc(1 / 14 * 100%);
  padding-bottom: 6rem;

  ${breakpoint('md', 'lg')`
    padding-right: 0;
  `}

  ${breakpoint('xs', 'md')`
    padding: 5rem 3rem 0 0;
  `}
`;

const ImgWrapper = styled.div`
  margin-right: calc(1 / 7 * 100%);

  ${breakpoint('xs', 'md')`
    margin-right: 3rem;
  `}
`;

const Img = styled(BackgroundImage)`
  padding-bottom: 82%;
  background-size: cover;
  position: relative;
  border-radius: 4px;
  overflow: hidden;

  ::before {
    border-radius: 4px;
    mix-blend-mode: hard-light;
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url(${({ fluid }) => fluid.src});
    opacity: 0.5;
    background-size: cover;
    overflow: hidden;
  }

  :hover:before {
    animation: ${Glitch} 0.3s infinite;
  }
`;

const AboutItemLeft = styled(Grid.Unit)`
  display: flex;
  align-self: stretch;
  flex-direction: column;
  justify-content: space-between;
  padding-top: 11.5rem;

  ${breakpoint('xs', 'md')`
    flex-direction: column-reverse;
    padding: 7rem 2rem 0;
  `}
`;

const SectionTitleWrapper = styled(SectionTitle)`
  width: calc(6 / 5 * 100%);
  padding-left: calc(3 / 10 * 100%);

  ${breakpoint('md', 'lg')`
    padding-left: calc(1 / 5 * 100%);
  `}

  ${breakpoint('xs', 'md')`
    padding-left: 0;
  `}
`;

const ColoredText = styled.span`
  color: ${({ color }: { color: string }) => color && `${color}`};
`;

const ParagraphText = styled.p`
  padding: 0 calc(1 / 5 * 100%);
  font-size: ${FONT_SIZE_LARGE};

  ${breakpoint('sm', 'lg')`
    font-size: 30px;
  `}

  ${breakpoint('xs', 'lg')`
    padding-left: 0;
  `}

  ${breakpoint('sm', 'md')`
    padding-top: 3rem;
  `}

  ${breakpoint('xs', 'sm')`
    padding-top: 2.5rem;
    font-size: ${FONT_SIZE_MEDIUM};
  `}
`;

const Color = ({ children, color }: { children: string; color: string }) => (
  <ColoredText color={color}>{children}</ColoredText>
);

const Text = ({ children }: { children: string }) => (
  <ParagraphText>{children}</ParagraphText>
);

const AboutUsItemOdd: React.SFC<AboutUsItemPropsType> = ({
  item,
  sectionTitle,
}: AboutUsItemPropsType) => {
  const options = {
    renderMark: {
      [MARKS.ITALIC]: text => <Color color={item.color}>{text}</Color>,
    },
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => <Text>{children}</Text>,
    },
  };

  return (
    <AboutItemWrapper>
      <Grid>
        <AboutItemLeft size={{ xs: 1, md: 5 / GRID_COLUMNS }}>
          {documentToReactComponents(get(item, 'lead.json', ''), options)}
          <Fade left={true} distance="50px">
            <SectionTitleWrapper
              sectionTitle={sectionTitle}
              options={{
                letters: [
                  { indexes: [2], color: `${COLOR_GREEN}` },
                  { indexes: [3, 4], color: `${COLOR_MAGENTA}` },
                ],
                breakLineIndex: 5,
              }}
            />
          </Fade>
        </AboutItemLeft>
        <Grid.Unit
          size={{ xs: 1, sm: 10 / GRID_COLUMNS, md: 7 / GRID_COLUMNS }}
        >
          <AboutItemRight>
            <Fade top={true} distance="50px">
              <ImgWrapper>
                <Img Tag="div" fluid={get(item, 'photo.fluid', '')} />
              </ImgWrapper>
            </Fade>
            <Fade top={true} distance="50px">
              <AboutUsItemPerson
                item={item}
                arrowColor={COLOR_GREEN}
                odd={true}
              />
            </Fade>
          </AboutItemRight>
        </Grid.Unit>
      </Grid>
    </AboutItemWrapper>
  );
};

export default AboutUsItemOdd;
