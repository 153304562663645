import map from 'lodash/map';
import React, { useState } from 'react';
import Swiper from 'react-id-swiper';
import Fade from 'react-reveal/Fade';
import styled, { keyframes } from 'styled-components';
import breakpoint from 'styled-components-breakpoint';

import NextMobileSvg from '../../assets/img/svg/arrow-left.svg';
import PrevMobileSvg from '../../assets/img/svg/modal-arrow.svg';
import SplashVideo from './SplashVideo';

import { isSafari } from '../../utils';

import {
  COLOR_MAGENTA,
  EASE_IN_OUT_CUBIC,
  FONT_SIZE_XXXLARGE,
} from '../../styles/Variables';

export const GlitchText = keyframes`
    0% {
			transform: translate3d(0, 0, 0) scale3d(1, 1, 1);
			-webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
			clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
		}
		1% {
			transform: translate3d(calc(-1 * var(--gap-horizontal)), 0, 0) scale3d(-1, -1, 1);
			-webkit-clip-path: polygon(0 20%, 100% 20%, 100% 21%, 0 21%);
			clip-path: polygon(0 20%, 100% 20%, 100% 21%, 0 21%);
		}
		10% {
			-webkit-clip-path: polygon(0 33%, 100% 33%, 100% 33%, 0 33%);
			clip-path: polygon(0 33%, 100% 33%, 100% 33%, 0 33%);
		}
		20% {
			-webkit-clip-path: polygon(0 44%, 100% 44%, 100% 44%, 0 44%);
			clip-path: polygon(0 44%, 100% 44%, 100% 44%, 0 44%);
		}
		25% {
			-webkit-clip-path: polygon(0 50%, 100% 50%, 100% 20%, 0 20%);
			clip-path: polygon(0 50%, 100% 50%, 100% 20%, 0 20%);
		}
		30% {
			-webkit-clip-path: polygon(0 70%, 100% 70%, 100% 70%, 0 70%);
			clip-path: polygon(0 70%, 100% 70%, 100% 70%, 0 70%);
		}
		35% {
			-webkit-clip-path: polygon(0 80%, 100% 80%, 100% 80%, 0 80%);
			clip-path: polygon(0 80%, 100% 80%, 100% 80%, 0 80%);
		}
		40% {
			-webkit-clip-path: polygon(0 50%, 100% 50%, 100% 55%, 0 55%);
			clip-path: polygon(0 50%, 100% 50%, 100% 55%, 0 55%);
		}
		45% {
			-webkit-clip-path: polygon(0 70%, 100% 70%, 100% 80%, 0 80%);
			clip-path: polygon(0 70%, 100% 70%, 100% 80%, 0 80%);
		}
		49% {
			transform: translate3d(calc(-1 * var(--gap-horizontal)), 0, 0) scale3d(-1, -1, 1);
		}
    50%,
		100% {
			transform: translate3d(0, 0, 0) scale3d(1, 1, 1);
			-webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
			clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
		}
`;

const SplashSliderWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100vh;

  .swiper-container {
    height: 100vh;
    z-index: 2;
  }

  & .swiper-button-next,
  .swiper-button-prev {
    width: auto;
    height: auto;
    background: none;
    top: auto;
    bottom: 2rem;
    transform: translateY(0);

    & svg {
      pointer-events: none;
    }

    &.swiper-button-disabled {
      opacity: 0.5;
    }
  }
`;

const SplashSliderTitleText = styled.span`
  display: inline-block;
`;

const SplashSliderTitle = styled.h1`
  font-weight: 900;
  font-size: ${FONT_SIZE_XXXLARGE};
  opacity: ${({ active }) => (active ? 1 : 0.7)};
  position: relative;
  overflow: hidden;
  display: inline-block;
  padding: 0 10px;


  ${breakpoint('lg', 'xl')`
    font-size: 60px;
  `}

  ${breakpoint('xs', 'lg')`
    .swiper-slide-active & {
      opacity: 1;
    }
  `}

  ${breakpoint('xs', 'lg')`
    display: none;
  `}

  ::before {
    content: '';
    position: absolute;
    will-change: transform;
    width: calc(100% + 20px);
    background: ${COLOR_MAGENTA};
    z-index: -1;
    transition: 0.4s ${EASE_IN_OUT_CUBIC};

    opacity: ${({ active }) => {
      if (active) {
        return 1;
      }

      if (isSafari && !active) {
        return 0;
      }

      return 1;
    }};

    transform: ${({ active }) =>
      isSafari
        ? 'translateY(-50%) '
        : active
        ? 'translate(0, -50%)'
        : 'translate(-100%, -50%)'};

    height: ${({ active }) => (isSafari ? '20px' : active ? '20px' : '100px')};

    ${() =>
      isSafari
        ? `
          height: 20px;
          left: 0;
          top: 50%;
          transform: translateY(-50%);`
        : `
          left: -10px;
          top: 50%;
      `}
  }
`;

const SplashSliderTitleWrapper = styled.div`
  position: relative;
  z-index: 1;
  min-width: 33.33%;
  text-align: center;
  padding: 0 50px;

  ${breakpoint('xs', 'lg')`
    text-align: center;
    margin: 0 auto;
  `}

  :hover {
    cursor: pointer;

    ${SplashSliderTitleText} {
      animation-name: ${GlitchText};
      animation-duration: 0.7s;
      animation-timing-function: linear;
      animation-fill-mode: forwards;
      animation-iteration-count: infinite;
    }
  }

  :first-child {
    text-align: right;
  }

  :last-child {
    text-align: left;
  }
`;
const SplashSliderNavigation = styled.div`
  display: flex;
  position: absolute;
  min-width: 100%;
  z-index: 2;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const SplashVideoWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

const tabletWidth =
  typeof window !== 'undefined' &&
  window.matchMedia('(max-width: 1023px)').matches;

const swiperParams = {
  initialSlide: 1,
  freeMode: true,
  speed: 100,
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  },
  renderPrevButton() {
    return (
      tabletWidth && (
        <div className="swiper-button-prev">
          <NextMobileSvg />
        </div>
      )
    );
  },
  renderNextButton() {
    return (
      tabletWidth && (
        <div className="swiper-button-next">
          <PrevMobileSvg />
        </div>
      )
    );
  },
};

const SplashSlider = ({ splashContent }) => {
  const [swiper, updateSwiper] = useState(null);
  const [slideIndex, updateSlideIndex] = useState(swiperParams.initialSlide);

  const goTo = index => {
    if (swiper === null) {
      updateSlideIndex(index);
    }
  };

  const createLeadWords = (title, i) => (
    <SplashSliderTitleWrapper onClick={() => goTo(i)}>
      <Fade delay={i * 200 + 200}>
        <SplashSliderTitle active={slideIndex === i}>
          <SplashSliderTitleText>{title}</SplashSliderTitleText>
        </SplashSliderTitle>
      </Fade>
    </SplashSliderTitleWrapper>
  );

  const swiperEvents = {
    slideChange() {
      updateSlideIndex(this.activeIndex);
    },
  };

  const slides = map(splashContent, ({ videoFile, title }, i) => (
    <div>
      <SplashSliderNavigation>
        {createLeadWords(title, i)}
      </SplashSliderNavigation>
    </div>
  ));

  return typeof window !== 'undefined' ? (
    <SplashSliderWrapper>
      <SplashVideoWrapper>
        {map(splashContent, ({ videoFile, videoPlaceholder, title }, i) => (
          <SplashVideo
            videoFile={videoFile}
            videoPlaceholder={videoPlaceholder}
            play={slideIndex === i}
          />
        ))}
      </SplashVideoWrapper>
      {!tabletWidth ? (
        <SplashSliderNavigation>
          {map(splashContent, ({ title }, i) => createLeadWords(title, i))}
        </SplashSliderNavigation>
      ) : (
        <Swiper
          getSwiper={updateSwiper}
          {...{ ...swiperParams, on: swiperEvents }}
        >
          {slides}
        </Swiper>
      )}
    </SplashSliderWrapper>
  ) : null;
};

export default SplashSlider;
