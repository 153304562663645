import map from 'lodash/map';
import React from 'react';
import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';

import { GlitchText } from '../Splash/SplashSlider';

import { COLOR_GREEN, EASE_IN_OUT_CUBIC } from '../../styles/Variables';
import { isSafari } from '../../utils';
import { WorksSliderCategoriesPropsType } from './types';

const WroksSliderCategoriesWrapper = styled.ul`
  display: flex;
  justify-content: flex-end;

  ${breakpoint('xs', 'md')`
    padding: 0 2rem;
    justify-content: flex-start;
    overflow-x: auto;
  `}

  ::-webkit-scrollbar {
    display: none;
  }
`;

const CategoriesItemButton = styled.button`
  height: auto;
  overflow: hidden;
  position: relative;
  opacity: ${({ active }) => (active ? 1 : 0.7)};
  font-size: 18px;
  color: white;
  cursor: pointer;

  :hover {
    animation-name: ${GlitchText};
    animation-duration: 0.7s;
    animation-timing-function: linear;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
  }

  ::before {
    content: '';
    position: absolute;
    will-change: transform;
    width: calc(100% + 20px);
    background: ${COLOR_GREEN};
    z-index: -1;
    transition: transform 0.4s ${EASE_IN_OUT_CUBIC},
      height 0.4s ${EASE_IN_OUT_CUBIC}, opacity 0.1s ease-in;

    opacity: ${({ active }) => {
      if (active) {
        return 1;
      }

      if (isSafari && !active) {
        return 0;
      }

      return 1;
    }};

    transform: ${({ active }) =>
      isSafari
        ? 'translateY(-50%) '
        : active
        ? 'translate(0, -50%)'
        : 'translate(-100%, -50%)'};

    height: ${({ active }) => (isSafari ? '7px' : active ? '7px' : '50px')};

    ${() =>
      isSafari
        ? `
          left: 0;
          top: 50%;`
        : `
          left: -10px;
          top: 50%;
      `}
  }
`;

const CategoriesItem = styled.li`
  padding: 0 2rem;
  position: relative;
  z-index: 1;
  flex: none;
  cursor: pointer;
`;

const WorksSliderCategories: React.SFC<WorksSliderCategoriesPropsType> = ({
  activeCategory,
  categories,
  filterWorksDataHandler,
}: WorksSliderCategoriesPropsType) => (
  <WroksSliderCategoriesWrapper>
    {map(categories, category => (
      <CategoriesItem
        key={category.slug}
        onClick={() => filterWorksDataHandler(category.slug)}
      >
        <CategoriesItemButton active={activeCategory === category.slug}>
          {category.title}
        </CategoriesItemButton>
      </CategoriesItem>
    ))}
  </WroksSliderCategoriesWrapper>
);

export default WorksSliderCategories;
