import find from 'lodash/find';
import React, { useState } from 'react';
import Swiper from 'react-id-swiper';
import Fade from 'react-reveal/Fade';
import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';

import Modal from './WorksModal';
import WorksSliderCategories from './WorksSliderCategories';
import WorksSliderItem from './WorksSliderItem';

import NextMobileSvg from '../../assets/img/svg/arrow-left.svg';
import PrevMobileSvg from '../../assets/img/svg/modal-arrow.svg';
import PrevSvg from '../../assets/img/svg/next.svg';
import NextSvg from '../../assets/img/svg/prev.svg';

import { WorksSliderPropsType } from './types';

const WorkSliderWrapper = styled.div`
  & .swiper-container {
    padding: 4.5rem 0;

    ${breakpoint('xs', 'md')`
      padding-top: 7.5rem;
    `}
  }

  & .swiper-button-next,
  .swiper-button-prev {
    width: auto;
    height: auto;
    background: none;

    ${breakpoint('xs', 'sm')`
      top: auto;
      bottom: 1.5rem;
      transform: translateY(0);
    `}

    & svg {
      pointer-events: none;
    }

    &.swiper-button-disabled {
      opacity: 0;

      ${breakpoint('xs', 'sm')`
        opacity: .5;
      `}
    }
  }
`;

const Slide = styled.div``;

const SwiperWrapper = styled.div`
  & ${Slide} {
    width: auto;
  }
`;

const mobileWidth =
  typeof window !== 'undefined' &&
  window.matchMedia('(max-width: 576px)').matches;

const params = {
  slidesPerView: 'auto',
  spaceBetween: 35,
  freeMode: true,
  loop: false,
  slidesOffsetBefore: 150,
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  },
  renderPrevButton() {
    return (
      <div className="swiper-button-prev">
        {mobileWidth ? <NextMobileSvg /> : <NextSvg />}
      </div>
    );
  },
  renderNextButton() {
    return (
      <div className="swiper-button-next">
        {mobileWidth ? <PrevMobileSvg /> : <PrevSvg />}
      </div>
    );
  },
  on: {
    init() {
      // Swiper
      setTimeout(() => this.update(), 500);
    },
  },

  breakpoints: {
    576: {
      slidesOffsetBefore: 0,
      spaceBetween: 20,
      centeredSlides: true,
      freeMode: false,

      renderPrevButton() {
        return (
          <div className="swiper-button-prev">
            <PrevMobileSvg />
          </div>
        );
      },
      renderNextButton() {
        return (
          <div className="swiper-button-next">
            <NextMobileSvg />
          </div>
        );
      },
    },
  },
};

const WorksSlider: React.SFC<WorksSliderPropsType> = ({
  categories,
  worksData,
  workItems,
  modalStrings,
}: WorksSliderPropsType) => {
  const [open, toggleModal] = useState(false);
  const [activeId, setId] = useState('');
  const [activeCategory, setCategory] = useState('all');

  const openModalWithProperData = (id: string) => {
    if (typeof window !== 'undefined') {
      document.body.style.height = '100vh';
      document.body.style.overflow = 'hidden';
    }
    toggleModal(!open);
    setId(id);
  };

  const filterWorksData = category => {
    setCategory(category);
  };

  const changeItem = () => {
    const currentItemIndex = worksData.indexOf(
      worksData.find(item => item.id === activeId)
    );
    const nextItemId = worksData[currentItemIndex + 1]
      ? worksData[currentItemIndex + 1].id
      : worksData[0].id;

    setId(nextItemId);
  };

  return (
    <WorkSliderWrapper className="custom-cursor">
      <Fade delay={500}>
        <WorksSliderCategories
          activeCategory={activeCategory}
          categories={categories}
          filterWorksDataHandler={filterWorksData}
        />
      </Fade>

      <SwiperWrapper>
        <Swiper {...params}>
          {worksData
            .filter(item =>
              item.category.some(itemCategory =>
                [itemCategory.slug, 'all'].includes(activeCategory)
              )
            )
            .map((item, i) => (
              <Slide key={i}>
                <Fade right={true} distance="50px" delay={100 * i}>
                  <WorksSliderItem
                    index={i}
                    {...item}
                    openModal={openModalWithProperData}
                    open={open}
                  />
                </Fade>
              </Slide>
            ))}
        </Swiper>
      </SwiperWrapper>

      <Modal
        open={open}
        activeId={activeId}
        toggleModal={toggleModal}
        modalStrings={modalStrings}
        changeItemHandler={changeItem}
        item={find(workItems, item => item.id === activeId)}
      >
        <h1>Hello Modal</h1>
      </Modal>
    </WorkSliderWrapper>
  );
};

export default WorksSlider;
