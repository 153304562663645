import { FluidObject } from 'gatsby-image';
import React from 'react';
import Swiper from 'react-id-swiper';
import Fade from 'react-reveal/Fade';
import styled, { css, keyframes } from 'styled-components';
import breakpoint from 'styled-components-breakpoint';
import Grid from 'styled-components-grid';

import FeedbackGlitchLeft from '../../assets/img/svg/feedback-glitch-0.svg';
import FeedbackGlitchRight from '../../assets/img/svg/feedback-glitch-1.svg';
import FeedbackGlitchMobileRight from '../../assets/img/svg/feedback-glitch-mobile.svg';
import MenuArrowSvg from '../../assets/img/svg/menu-arrow.svg';

import {
  COLOR_BLACK,
  COLOR_BLUE,
  COLOR_GREEN,
  COLOR_GREY_LIGHT,
  COLOR_WHITE,
  GRID_COLUMNS,
} from '../../styles/Variables';

const FeedBackWrapper = styled.div`
  width: 100%;
  padding-top: 20rem;
  position: relative;

  & .swiper-container-horizontal .swiper-pagination {
    width: 41%;
    padding-left: calc(1 / 12 * 100%);
    bottom: 1.5rem;

    @media screen and (max-width: 1023px) {
      width: 100%;
      padding-top: 3.5rem;
      padding-left: 0;
      position: relative;
    }

    & .swiper-pagination-bullet {
      width: 1rem;
      height: 1rem;
      background-color: ${COLOR_GREY_LIGHT};

      &-active {
        background-color: transparent;
        border: 1px solid ${COLOR_WHITE};
      }
    }
  }
`;

const Slide = styled.div`
  display: block;
  padding-top: 2rem;
  background: ${COLOR_BLACK};

  ${breakpoint('xs', 'xl')`
    padding-top: 0;
  `}
`;

const SlideInnerWrapper = styled(Grid)`
  height: 100%;
`;

const SlideLeftWrapper = styled(Grid.Unit)`
  padding-left: calc(1 / 12 * 100%);
  padding-bottom: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;

  ${breakpoint('xs', 'lg')`
    justify-content: flex-start;
    padding-left: 0;
  `}
`;

const SlideRightWrapper = styled(Grid.Unit)`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  padding-top: 3.5rem;
  padding-right: calc(2 / 12 * 100%);
  position: relative;

  ${breakpoint('xs', 'lg')`
    padding-right: 0;
    padding-top: 2.5rem;
  `}
`;

const SlideDescription = styled.p`
  font-weight: 300;

  ${breakpoint('xs', 'lg')`
    padding-bottom: 1.5rem;
  `}
`;

const SlideCompany = styled.p`
  margin-top: auto;
  padding-top: 1rem;
  font-weight: 500;
  color: ${COLOR_GREEN};
  text-transform: uppercase;
`;

const SlideAuthor = styled.p`
  padding-bottom: 1rem;

  ${breakpoint('xs', 'lg')`
    padding-bottom: 2.5rem;
  `}
`;

const ArrowWrapper = styled(MenuArrowSvg)`
  position: absolute;
  top: -1rem;
  left: -4.5rem;
  transform: 'rotate(180deg)';

  ${breakpoint('xs', 'xl')`
    display: none;
  `}

  & path,
  rect {
    fill: ${COLOR_BLUE};
  }
`;

const move1 = keyframes`
  0% {
    transform: translateX(0);
  }

  50% {
    transform: translateX(-20px);
  }

  100% {
    transform: translateX(0px);
  }
`;

const move2 = keyframes`
  0% {
    transform: translateX(0);
  }

  50% {
    transform: translateX(40px);
  }

  100% {
    transform: translateX(0px);
  }
`;

const StyledFeedbackGlitchCSS = css`
  z-index: 0;
  position: absolute;
`;

const StyledFeedbackGlitchLeft = styled(FeedbackGlitchLeft)`
  ${StyledFeedbackGlitchCSS}
  top: 25.8%;
  left: 0;
  display: none;
  @media screen and (min-width: 1390px) {
    left: calc(695px - 50vw);
  }
  ${breakpoint('lg')`
    animation: ${move1} 10s ease-in infinite;
    display: block;
  `}
`;

const StyledFeedbackGlitchRight = styled(FeedbackGlitchRight)`
  ${StyledFeedbackGlitchCSS}
  top: 8%;
  right: 0;
  @media screen and (min-width: 1390px) {
    right: calc(695px - 50vw);
  }
  display: none;
  ${breakpoint('lg')`
    display: block;
    animation: ${move2} 11s ease-in infinite;
  `}
`;

const StyledFeedbackGlitchMobileRight = styled(FeedbackGlitchMobileRight)`
  ${StyledFeedbackGlitchCSS}
  top: 10%;
  right: -20px;
  display: block;
  ${breakpoint('lg')`
    display: none;
  `}
`;

const params = {
  disableOnInteraction: false,
  pagination: {
    el: '.swiper-pagination',
    type: 'bullets',
    clickable: true,
  },
  observer: true,
  observeParents: true,
  speed: 1000,
  effect: 'fade',
  loop: true,
  autoplay: {
    delay: 5000,
  },
  on: {
    init() {
      // Swiper
      setTimeout(() => this.update(), 500);
    },
  },
};

interface FeedBackTypes {
  feedbackList: Array<{
    author: string;
    company: string;
    description: {
      description: string;
    };
    logo: {
      fluid: FluidObject;
    };
  }>;
}

const FeedBack = (props: FeedBackTypes) => (
  <FeedBackWrapper>
    <StyledFeedbackGlitchLeft />
    <StyledFeedbackGlitchRight />
    <StyledFeedbackGlitchMobileRight />
    <Fade top={true} distance="50px">
      <Swiper {...params}>
        {props.feedbackList.map(
          (
            { author, company, description: { description }, logo: { fluid } },
            i
          ) => (
            <Slide key={i}>
              <SlideInnerWrapper>
                <SlideLeftWrapper size={{ xs: 1, md: 5 / GRID_COLUMNS }}>
                  <img src={fluid.src} />
                </SlideLeftWrapper>
                <SlideRightWrapper size={{ xs: 1, md: 7 / GRID_COLUMNS }}>
                  <ArrowWrapper
                    textAnchor="end"
                    preserveAspectRatio="xMaxYMin meet"
                  />
                  <SlideAuthor>{author}</SlideAuthor>
                  <SlideDescription>{description}</SlideDescription>
                  <SlideCompany>{company}</SlideCompany>
                </SlideRightWrapper>
              </SlideInnerWrapper>
            </Slide>
          )
        )}
      </Swiper>
    </Fade>
  </FeedBackWrapper>
);

export default FeedBack;
