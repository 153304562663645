import { graphql, useStaticQuery } from 'gatsby';
import { get, map } from 'lodash';
import React from 'react';
import styled, { css, keyframes } from 'styled-components';
import breakpoint from 'styled-components-breakpoint';

import kebabCase from 'lodash/kebabCase';
import AboutGlitchLeft from '../../assets/img/svg/about-glitch-0.svg';
import AboutGlitchRight from '../../assets/img/svg/about-glitch-1.svg';
import AboutGlitchMobileRight from '../../assets/img/svg/about-glitch-mobile-1.svg';
import AboutGlitchMobile from '../../assets/img/svg/about-us-glitch-mobile.svg';
import AboutGlitch from '../../assets/img/svg/about-us-glitch.svg';
import { withLanguageFilter } from '../../enhancers/withLanguage';
import AboutUsBottom from './AboutUsBottom';
import AboutUsItemEven from './AboutUsItemEven';
import AboutUsItemOdd from './AboutUsItemOdd';
import { AboutUsPropsType } from './types';

const AboutUsGlitches = styled.div`
  position: relative;
`;

const AboutUsWrapper = styled.section`
  padding-top: 4.5rem;
  max-width: 100%;

  ${breakpoint('md', 'lg')`
    padding-top: 3rem;
  `}

  ${breakpoint('xs', 'md')`
  padding-top: 1.5rem;
  `}
`;

const move1 = keyframes`
  0% {
    transform: translateX(0);
  }

  50% {
    transform: translateX(-20px);
  }

  100% {
    transform: translateX(0px);
  }
`;

const move2 = keyframes`
  0% {
    transform: translateX(0);
  }

  50% {
    transform: translateX(40px);
  }

  100% {
    transform: translateX(0px);
  }
`;

const StyledAboutGlitchCSS = css`
  z-index: 0;
  position: absolute;
`;

const StyledAboutGlitchLeft = styled(AboutGlitchLeft)`
  ${StyledAboutGlitchCSS}
  top: 28%;
  left: 0;
  display: none;
  ${breakpoint('lg')`
    animation: ${move1} 10s ease-in infinite;
    display: block;
  `}
`;

const StyledAboutGlitchRight = styled(AboutGlitchRight)`
  ${StyledAboutGlitchCSS}
  bottom: 20%;
  right: 0;
  display: none;
  ${breakpoint('lg')`
    display: block;
    animation: ${move2} 11s ease-in infinite;
  `}
`;

const StyledAboutGlitchMobileRight = styled(AboutGlitchMobileRight)`
  ${StyledAboutGlitchCSS}
  top: 30%;
  right: 0;
  display: block;
  ${breakpoint('lg')`
    display: none;  
  `}
`;

const StyledAboutGlitchMobile = styled(AboutGlitchMobile)`
  ${StyledAboutGlitchCSS}
  top: 50%;
  right: 0;
  left: 0;
  transform: translateY(-50%);
  display: block;
  ${breakpoint('lg')`
    display: none;  
  `}
`;

const StyledAboutGlitch = styled(AboutGlitch)`
  ${StyledAboutGlitchCSS}
  top: 50%;
  right: 0;
  left: 0;
  transform: translateY(-50%);
  display: none;
  ${breakpoint('lg')`
    display: block;  
  `}
`;

const prepareAboutItems = ({
  aboutUs: items,
  sectionTitle,
}: {
  aboutUs: [];
  sectionTitle: string;
}) =>
  map(items, (item, i: number) =>
    i % 2 === 0 ? (
      <AboutUsItemOdd
        key={i}
        item={item}
        sectionTitle={i === 0 ? sectionTitle : ''}
      />
    ) : (
      <AboutUsItemEven key={i} item={item} />
    )
  );

const AboutUs: React.SFC<AboutUsPropsType> = ({
  nodeLocale,
}: AboutUsPropsType) => {
  const data = useStaticQuery(aboutUsQuery);
  const aboutUsData = withLanguageFilter({ nodeLocale, data });
  const navTitle = get(aboutUsData, 'contentfulAboutUs.navigation.title');

  return (
    <AboutUsWrapper id={kebabCase(navTitle)}>
      <AboutUsGlitches>
        <StyledAboutGlitch />
        <StyledAboutGlitchMobile />
        <StyledAboutGlitchLeft />
        <StyledAboutGlitchRight />
        <StyledAboutGlitchMobileRight />
        {prepareAboutItems(get(aboutUsData, 'contentfulAboutUs', []))}
      </AboutUsGlitches>

      <AboutUsBottom
        lead={get(aboutUsData, 'contentfulAboutUs.leadBottom.leadBottom', '')}
        video={get(aboutUsData, 'contentfulAboutUs.videoBottom.file')}
      />
    </AboutUsWrapper>
  );
};

export default AboutUs;

export const aboutUsQuery = graphql`
  query AboutUs {
    allContentfulAboutUs {
      edges {
        node {
          node_locale
          sectionTitle
          navigation {
            title
          }
          aboutUs {
            role
            description {
              description
            }
            color
            name
            lead {
              json
            }
            photo {
              fluid(maxWidth: 860, quality: 100) {
                ...GatsbyContentfulFluid_withWebp
              }
            }
          }
          leadBottom {
            leadBottom
          }
          videoBottom {
            file {
              url
            }
          }
        }
      }
    }
  }
`;
