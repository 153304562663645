import map from 'lodash/map';
import split from 'lodash/split';
import React from 'react';
import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';
import { FONT_SIZE_XXLARGE, FONT_SIZE_XXXXLARGE } from '../../styles/Variables';

const SectionTitleContainer = styled.div`
  position: relative;
  z-index: 1;
`;

const TitleContainerText = styled.p`
  font-size: ${FONT_SIZE_XXXXLARGE};
  font-weight: 900;
  line-height: 0.8;

  ${breakpoint('lg', 'xl')`
    font-size: 150px;
  `}

  ${breakpoint('sm', 'lg')`
    font-size: 120px;
  `}

  ${breakpoint('xs', 'sm')`
    font-size: ${FONT_SIZE_XXLARGE};
  `}
`;

const prepareSectionTitle = (
  title: string,
  {
    letters,
  }: {
    letters: Array<{
      indexes: number[];
      color: string;
    }>;
  }
) =>
  map(split(title, ''), (letter: string, i: number) => {
    const letterToColor = letters.reduce((acc, { indexes, color }) => {
      return indexes.includes(i) ? (
        <span style={{ color: `${color}` }}>{letter}</span>
      ) : (
        acc
      );
    }, null);

    return letterToColor ? letterToColor : letter;
  });

export interface SectionTitleTitlePropsType {
  sectionTitle: string;
  options: {
    letters: Array<{
      indexes: number[];
      color: string;
    }>;
    breakLineIndex?: number;
  };
}

const SectionTitle: React.SFC<SectionTitleTitlePropsType> = ({
  sectionTitle,
  options,
  className,
}: SectionTitleTitlePropsType) => {
  const letters = prepareSectionTitle(sectionTitle, options);
  const { breakLineIndex } = options;

  return breakLineIndex ? (
    <SectionTitleContainer className={className}>
      <TitleContainerText>
        {[...letters.slice(0, breakLineIndex)]}
      </TitleContainerText>
      <TitleContainerText>
        {[...letters.slice(breakLineIndex)]}
      </TitleContainerText>
    </SectionTitleContainer>
  ) : (
    <SectionTitleContainer className={className}>
      <TitleContainerText>{[...letters]}</TitleContainerText>
    </SectionTitleContainer>
  );
};

export default SectionTitle;
