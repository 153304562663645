import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS, MARKS } from '@contentful/rich-text-types';
import BackgroundImage from 'gatsby-background-image';
import get from 'lodash/get';
import React from 'react';
import Fade from 'react-reveal/Fade';
import styled, { keyframes } from 'styled-components';
import breakpoint from 'styled-components-breakpoint';
import Grid from 'styled-components-grid';

import AboutUsItemPerson from './AboutUsItemPerson';

import {
  COLOR_BLUE,
  FONT_SIZE_LARGE,
  FONT_SIZE_MEDIUM,
  GRID_COLUMNS,
  LAYOUT_MAX_WIDTH,
} from '../../styles/Variables';
import { AboutUsItemPropsType } from './types';

export const Glitch = keyframes`
  0%{
    background-position: 0 0;
    filter:hue-rotate(0deg);
  }
  10%{
    background-position: 2px 0px;
  }
  15%{
    background-position: -4px 0px;
  }
  
  20%{
    background-position: -2px 0;
  }
  30%{
    background-position: 6px 0;
  }
  40%{
    background-position: -8px 0;
  }
  50%{
    background-position: -12px 0;
  }
  60%{
    background-position: 8px 0;
  }
  70%{
    background-position: 0 11px;
  }
  80%{
    background-position: -20px -5px;
  }
  100%{
    background-position: 0 0;
    filter:hue-rotate(360deg);
  }
`;

const AboutItemWrapper = styled.div`
  max-width: ${LAYOUT_MAX_WIDTH};
  margin: 0 auto;
  padding: 4.5rem 3rem 0;

  ${breakpoint('md', 'lg')`
    padding-top: 4.5rem 2rem 0;
  `}

  ${breakpoint('xs', 'md')`
    padding: 4rem 0;
  `}
`;

const ImgWrapper = styled.div`
  margin: 0 calc(1 / 5 * 100%) 0 calc(1 / 10 * 100%);

  ${breakpoint('xs', 'lg')`
    margin-right: 0;
  `}

  ${breakpoint('xs', 'sm')`
    margin-top: 5.5rem;
    margin-left: 8rem;
    margin-right: 1rem;
  `}
`;

const Img = styled(BackgroundImage)`
  position: relative;
  padding-bottom: 130%;
  background-size: cover;
  border-radius: 4px;
  overflow: hidden;

  ::before {
    mix-blend-mode: hard-light;
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url(${({ fluid }) => fluid.src});
    opacity: 1;
    background-size: cover;
    border-radius: 4px;
    overflow: hidden;
  }

  :hover:before {
    animation: ${Glitch} 0.3s infinite;
  }
`;

const ColoredText = styled.span`
  color: ${({ color }: { color: string }) => color && `${color}`};
`;

const ParagraphText = styled.p`
  padding: 21rem 0 0 calc(1 / 4 * 100%);
  font-size: ${FONT_SIZE_LARGE};

  ${breakpoint('xs', 'xl')`
    padding-left: calc(1 / 8 * 100%);
  `}

  ${breakpoint('xs', 'lg')`
    padding-top: 5rem;
  `}

  ${breakpoint('sm', 'lg')`
    font-size: 30px;
  `}

  ${breakpoint('xs', 'sm')`
    padding: 7rem 2rem 0 12rem;
    font-size: ${FONT_SIZE_MEDIUM};
  `}
`;

const Color = ({ children, color }: { children: string; color: string }) => (
  <ColoredText color={color}>{children}</ColoredText>
);

const Text = ({ children }: { children: string }) => (
  <ParagraphText>{children}</ParagraphText>
);

const AboutUsItemEven: React.SFC<AboutUsItemPropsType> = ({
  item,
}: AboutUsItemPropsType) => {
  const options = {
    renderMark: {
      [MARKS.ITALIC]: text => <Color color={item.color}>{text}</Color>,
    },
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => <Text>{children}</Text>,
    },
  };

  return (
    <AboutItemWrapper>
      <Grid>
        <Grid.Unit
          size={{
            xs: 1,
            sm: 6 / GRID_COLUMNS,
            md: 5 / GRID_COLUMNS,
            lg: 4 / GRID_COLUMNS,
          }}
        >
          <Fade left={true} distance="50px">
            <AboutUsItemPerson item={item} arrowColor={COLOR_BLUE} />
          </Fade>
        </Grid.Unit>
        <Grid.Unit
          size={{
            xs: 1,
            sm: 6 / GRID_COLUMNS,
            md: 5 / GRID_COLUMNS,
            lg: 4 / GRID_COLUMNS,
          }}
        >
          <Fade bottom={true} distance="50px">
            <ImgWrapper>
              <Img Tag="div" fluid={get(item, 'photo.fluid', '')} />
            </ImgWrapper>
          </Fade>
        </Grid.Unit>
        <Grid.Unit size={{ xs: 1, lg: 4 / GRID_COLUMNS }}>
          <Fade right={true} distance="50px">
            <div>
              {documentToReactComponents(get(item, 'lead.json', ''), options)}
            </div>
          </Fade>
        </Grid.Unit>
      </Grid>
    </AboutItemWrapper>
  );
};

export default AboutUsItemEven;
