import BackgroundImage from 'gatsby-background-image';
import get from 'lodash/get';
import last from 'lodash/last';
import React from 'react';
import Fade from 'react-reveal/Fade';
import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';
import { COLOR_BLACK } from '../../styles/Variables';

import SplashMask from '../../assets/img/svg/splash-mask.svg';

const getYouTubeID = (url: string): string => last(url.split('/'));

const SplashVideoWrapper = styled.div`
  width: 500vw;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  ${breakpoint('lg', 'xl')`
    width: 200vw;
  `}

  ${breakpoint('xs', 'md')`
    width: 100vw;
  `}
`;

const SplashVideoFrame = styled.video`
  height: 70vh;
  width: 100%;

  ${breakpoint('lg')`
    height: calc(100vh - 200px);
  `}

  ${breakpoint('xs', 'md')`
    display: none;
  `}
`;

const StyledRect = styled.rect`
  -webkit-mask: url(#mask);
  mask: url(#mask);
  fill: ${COLOR_BLACK};
`;

const StyledMaskRect = styled.rect`
  fill: #fff;
`;

const StyledSplashMaskSvg = styled.svg`
  position: absolute;
  top: -2px;
  left: 0;
  z-index: 1;
  height: calc(70vh + 4px);
  width: 100%;
  pointer-events: none;

  ${breakpoint('lg')`
    height: calc(100vh - 196px);
  `}
`;

const ImgWrapper = styled.div`
  display: none;

  ${breakpoint('xs', 'md')`
    display: block;
    position: relative;
    height: calc(100vh - 200px);
  `}
`;

const Img = styled(BackgroundImage)`
  background-size: cover;
  position: relative;
  border-radius: 4px;
  overflow: hidden;
  height: 70vh;
`;

class SplashVideo extends React.PureComponent {
  public videoRef = React.createRef();
  public state = {
    in: false,
  };
  public componentDidMount() {
    const video = get(this.videoRef, 'current');
    if (video) {
      video.oncanplay = () =>
        setTimeout(() => {
          this.setState({ in: true }, () => this.props.play && video.play());
        }, 500);
    }
  }

  public componentDidUpdate() {
    const video = get(this.videoRef, 'current');
    if (this.props.play && video.paused) {
      video.play();
    } else if (!video.paused) {
      video.pause();
    }
  }

  public render() {
    const { videoFile, videoPlaceholder, play } = this.props;

    return (
      <SplashVideoWrapper>
        <StyledSplashMaskSvg
          xmlns="http://www.w3.org/2000/svg"
          preserveAspectRatio="xMidYMid slice"
        >
          <defs>
            <mask id="mask" x="0" y="0" width="100%" height="100%">
              <StyledMaskRect x="0" y="0" width="100%" height="100%" />
              <SplashMask y="2%" height="96%" />
            </mask>
          </defs>
          <StyledRect x="0" y="0" width="100%" height="100%" />
        </StyledSplashMaskSvg>
        <Fade duration={1000} in={this.state.in && play}>
          <SplashVideoFrame
            ref={this.videoRef}
            muted={true}
            loop={true}
            preload="auto"
            src={get(videoFile, 'file.url')}
          />
          <ImgWrapper>
            <Img Tag="div" fluid={get(videoPlaceholder, 'fluid', '')} />
          </ImgWrapper>
        </Fade>
      </SplashVideoWrapper>
    );
  }
}

export default SplashVideo;
