import { graphql, useStaticQuery } from 'gatsby';
import get from 'lodash/get';
import React from 'react';
import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';

import kebabCase from 'lodash/kebabCase';
import { withLanguageFilter } from '../../enhancers/withLanguage';
import WorksSlider from './WorksSlider';
import WorksTop from './WorksTop';

const WorksWrapper = styled.section`
  cursor: url(${({ customCursor }) => customCursor}), auto;
  padding: 4rem 3rem 6rem;

  ${breakpoint('xs', 'md')`
    padding: 4rem 0;
  `}
`;

interface GlobalStringTypes {
  worksStrings: {
    worksModalBackButton: string;
    worksModalButton: string;
  };
  nodeLocale: string;
}

const Works: React.SFC<GlobalStringTypes> = ({
  worksStrings,
  nodeLocale,
}: GlobalStringTypes) => {
  const data = useStaticQuery(worksQuery);
  const worksData = withLanguageFilter({ nodeLocale, data });
  const navTitle = get(worksData, 'contentfulWorks.navigation.title');

  return (
    <WorksWrapper
      customCursor={get(worksData, 'contentfulWorks.customCursor.fluid.src')}
      id={kebabCase(navTitle)}
    >
      <WorksTop worksData={get(worksData, 'contentfulWorks')} />
      <WorksSlider
        categories={get(worksData, 'contentfulCategory')}
        workItems={get(worksData, 'contentfulWorksItem')}
        worksData={get(worksData, 'contentfulWorks.works')}
        modalStrings={worksStrings}
      />
    </WorksWrapper>
  );
};

export default Works;

export const worksQuery = graphql`
  query Works {
    allContentfulWorks {
      edges {
        node {
          node_locale
          sectionTitle
          title
          subtitle
          customCursor {
            fluid(maxWidth: 100, quality: 100) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
          navigation {
            title
          }
          works {
            worksItem {
              title
              subtitle
              image {
                fluid(maxWidth: 380, quality: 100) {
                  ...GatsbyContentfulFluid_withWebp
                }
              }
            }
            id
            category {
              title
              slug
            }
          }
        }
      }
    }
    allContentfulWorksItem {
      edges {
        node {
          node_locale
          id
          listTitle
          list
          title
          linkName
          linkUrl
          clientHeading
          clientText
          descriptionHeading
          descriptionText {
            descriptionText
          }
          video {
            file {
              url
            }
          }
          logo {
            fluid(maxWidth: 200) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
        }
      }
    }
    allContentfulCategory {
      edges {
        node {
          node_locale
          slug
          title
        }
      }
    }
  }
`;
