import map from 'lodash/map';
import React, { useState } from 'react';
import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';

import NavigationMenuFooter from './NavigationMenuFooter';
import NavigationMenuItem from './NavigationMenuItem';

import {
  COLOR_GREY_LIGHT,
  COLOR_WHITE,
  EASE_IN_OUT_CUBIC,
} from '../../styles/Variables';
import NavigationMenuImages from './NavigationMenuImages';
import { MenuItemType, NavigationMenuPropsType } from './types';

const NavigationMenuWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  height: 100vh;
  width: calc(100vw - 225px);
  padding: 17.5rem 6rem;
  display: flex;
  z-index: 1;
  visibility: ${({ active }: NavigationMenuPropsType) =>
    active ? 'visible' : 'hidden'};

  @media screen and (max-height: 800px) and (min-width: 1023px) {
    padding: 11rem 2.5rem 7.5rem;
  }

  ${breakpoint('xs', 'lg')`
    width: 100%;
    padding: 10rem 2.5rem 2rem;
  `}

  &:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: ${COLOR_WHITE};
    will-change: transform;
    transition: 0.4s ${EASE_IN_OUT_CUBIC};
    transform: ${({ active }: NavigationMenuPropsType) =>
      `translateX(${active ? '0' : '100%'})`};
  }
`;

const NavigationMenuContainer = styled.div`
  width: 50%;
  position: relative;
  display: flex;
  align-content: center;

  ${breakpoint('xs', 'xl')`
    width: 100%;
  `}

  ${breakpoint('xs', 'lg')`
    align-items: flex-end;
    padding-bottom: 7rem;
  `}
`;

const NavigationMenuContent = styled.nav`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const NavigationMenu: React.SFC<NavigationMenuPropsType> = ({
  active,
  menuItems,
  socialItems,
  pathname,
  toggleActive,
}: NavigationMenuPropsType) => {
  const [activeIndex, toggleIndex] = useState();
  const images = map(menuItems, ({ image }: MenuItemType) => image);
  return (
    <NavigationMenuWrapper active={active}>
      <NavigationMenuImages images={images} activeIndex={activeIndex} />
      <NavigationMenuContainer>
        <NavigationMenuContent>
          {map(menuItems, (menuItem, i) => (
            <NavigationMenuItem
              {...menuItem}
              pathname={pathname}
              key={i}
              hoverHandler={toggleIndex}
              toggleActive={toggleActive}
              hoverIndex={i}
              activeIndex={activeIndex}
              activeMenu={active}
            />
          ))}
        </NavigationMenuContent>
        <NavigationMenuFooter activeMenu={active} socialItems={socialItems} />
      </NavigationMenuContainer>
    </NavigationMenuWrapper>
  );
};

export default NavigationMenu;
