import { Location } from '@reach/router';
import { graphql } from 'gatsby';
import get from 'lodash/get';
import React from 'react';
import styled from 'styled-components';

import AboutUs from '../components/AboutUs';
import Consulting from '../components/Consulting';
import Contact from '../components/Contact';
import Footer from '../components/Footer';
import Header from '../components/Header';
import Logo from '../components/Logo';
import Navigation from '../components/Navigation';
import Seo from '../components/Seo';
import Splash from '../components/Splash';
import Works from '../components/Works';

const StyledMain = styled.main`
  overflow: hidden;
`;

export default props => {
  const nodeLocale = get(props.pageContext, 'node_locale', '');
  const language = get(props.pageContext, 'language', '');
  return (
    <>
      <Seo nodeLocale={nodeLocale} />
      <Header>
        <Logo language={language} />
        <Location>
          {locationProps => (
            <Navigation
              {...locationProps}
              menuItems={get(props, 'data.menu.menuItems')}
              socialItems={get(props, 'data.socials.nodes')}
            />
          )}
        </Location>
      </Header>
      <StyledMain>
        <Splash nodeLocale={nodeLocale} />
        <AboutUs nodeLocale={nodeLocale} />
        <Works
          nodeLocale={nodeLocale}
          worksStrings={get(props, 'data.generalStrings')}
        />
        <Consulting nodeLocale={nodeLocale} />
        <Contact nodeLocale={nodeLocale} />
      </StyledMain>
      <Footer
        socialItems={get(props, 'data.socials.nodes')}
        footerStrings={get(props, 'data.generalStrings')}
      />
    </>
  );
};

export const query = graphql`
  query homePage($node_locale: String) {
    menu: contentfulMenu(node_locale: { eq: $node_locale }) {
      node_locale
      menuItems {
        title
        image {
          fluid(maxWidth: 500, quality: 100) {
            ...GatsbyContentfulFluid_withWebp
          }
        }
      }
    }
    socials: allContentfulSocials(
      filter: { node_locale: { eq: $node_locale } }
    ) {
      nodes {
        linkId
        link
      }
    }
    generalStrings: contentfulGeneralStrings(
      node_locale: { eq: $node_locale }
    ) {
      worksModalBackButton
      worksModalButton
      footerFirstColumnTitle
      footerSecondColumnTitle
      footerThirdColumnTItle
      footerCopyrights {
        json
      }
    }
  }
`;
