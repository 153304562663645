import map from 'lodash/map';
import React from 'react';
import Fade from 'react-reveal/Fade';
import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';
import Grid from 'styled-components-grid';

import SectionTitle from '../Base/SectionTitle';

import FacebookSvg from '../../assets/img/svg/facebook.svg';
import InstagramSvg from '../../assets/img/svg/instagram.svg';
import LinkedinSvg from '../../assets/img/svg/linkedin.svg';
import MediumSvg from '../../assets/img/svg/medium.svg';
import VimeoSvg from '../../assets/img/svg/vimeo.svg';
import YoutubeSvg from '../../assets/img/svg/youtube.svg';

import {
  COLOR_GREEN,
  COLOR_GREY,
  COLOR_GREY_LIGHT,
  COLOR_MAGENTA,
  EASE_IN_OUT_CUBIC,
  FONT_SIZE_XSMALL,
  GRID_COLUMNS,
} from '../../styles/Variables';
import { ContactTopPropsType } from './types';

const socialComponents = {
  facebook: FacebookSvg,
  instagram: InstagramSvg,
  youtube: YoutubeSvg,
  vimeo: VimeoSvg,
  linkedin: LinkedinSvg,
  medium: MediumSvg,
};

const ContactTopWrapper = styled(Grid)`
  padding-bottom: 8rem;
`;

const ContactTopLeftWrapper = styled(Grid.Unit)`
  padding-left: calc(2 / 12 * 100%);

  ${breakpoint('sm', 'lg')`
    padding-left: calc(1 / 12 * 100%);
  `}

  ${breakpoint('xs', 'sm')`
    padding-left: 0;
  `}
`;

const ContactTopRightWrapper = styled(Grid.Unit)`
  padding: 14rem calc(2 / 12 * 100%) 0 calc(1 / 12 * 100%);

  ${breakpoint('xs', 'lg')`
    padding-right: 0;
  `}

  ${breakpoint('xs', 'sm')`
    padding-top: 4rem;
    padding-left: 0;
  `}
`;

const ContactTitle = styled.h4`
  padding-bottom: 0.5rem;
`;

const ContactSocialItemsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: 3.5rem;
`;

const ContactSocialItemsLink = styled.a`
  color: ${COLOR_GREY_LIGHT};
  transition: 0.2s ${EASE_IN_OUT_CUBIC};
  font-size: ${FONT_SIZE_XSMALL};
  svg,
  g,
  path {
    transition: 0.2s ${EASE_IN_OUT_CUBIC};
    fill: ${COLOR_GREY_LIGHT};
    fill-opacity: 1;
  }

  :hover {
    color: ${COLOR_GREY};
    svg,
    g,
    path {
      fill: ${COLOR_GREY};
    }
  }
`;

const ContactTop: React.SFC<ContactTopPropsType> = ({
  contactData,
  socialItems,
}: ContactTopPropsType) => {
  const { title, subtitle, sectionTitle } = contactData;

  return (
    <ContactTopWrapper>
      <ContactTopLeftWrapper size={{ xs: 1, sm: 6 / GRID_COLUMNS }}>
        <Fade left={true} distance="50px">
          <SectionTitle
            sectionTitle={sectionTitle}
            options={{
              letters: [
                { indexes: [4, 5], color: `${COLOR_GREEN}` },
                { indexes: [6], color: `${COLOR_MAGENTA}` },
              ],
              breakLineIndex: 4,
            }}
          />
        </Fade>
      </ContactTopLeftWrapper>
      <ContactTopRightWrapper size={{ xs: 1, sm: 6 / GRID_COLUMNS }}>
        <Fade cascade={true}>
          <ContactTitle>{title}</ContactTitle>
          <p>{subtitle}</p>

          <ContactSocialItemsWrapper>
            {map(socialItems, ({ linkId, link }, i) => {
              const Component = socialComponents[linkId];
              if (Component) {
                return (
                  <ContactSocialItemsLink key={i} target="_blank" href={link}>
                    <Component link={link} />
                  </ContactSocialItemsLink>
                );
              }
            })}
          </ContactSocialItemsWrapper>
        </Fade>
      </ContactTopRightWrapper>
    </ContactTopWrapper>
  );
};

export default ContactTop;
