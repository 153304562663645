import Img from 'gatsby-image';
import React from 'react';
import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';

import {
  COLOR_GREEN,
  COLOR_WHITE,
  FONT_SIZE_MEDIUM,
  FONT_SIZE_XLARGE,
  FONT_SIZE_XXSMALL,
} from '../../styles/Variables';
import { WorksSliderItemPropsType } from './types';

const SliderItemWrapper = styled.div`
  display: flex;
  align-items: stretch;
  width: 35rem;
  height: 48rem;
  position: relative;

  ${breakpoint('xs', 'sm')`
    width: 29.5rem;
    height: 40.5rem;
    transform: scale(0.95);
    transition: .3s transform ease-in-out;

    .swiper-slide-active & {
      transform: scale(1);
    }
  `}
`;

const ImgWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 1;
`;

const ImgBlurWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  opacity: 0;
  transition: opacity 0.3s ease;

  & .gatsby-image-wrapper {
    overflow: visible !important;
  }

  /* TODO: move to scomponents */
  /* Maciek: how can i do that with gatsby image wrapper? */

  & img {
    filter: blur(30px);
  }

  ${SliderItemWrapper}:hover & {
    opacity: 1;
  }

  ${breakpoint('xs', 'sm')`
    .swiper-slide-active ${SliderItemWrapper} & {
      opacity: 1;
    }
  `};
`;

const SliderItemContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 3rem 2.5rem 6rem;
  position: relative;
  z-index: 1;
  cursor: pointer;
`;

const ItemNumber = styled.span`
  font-size: ${FONT_SIZE_MEDIUM};
  font-weight: 700;
`;

const ItemContentInfo = styled.div`
  transition: transform 0.3s 0.1s ease-in-out;

  ${SliderItemWrapper}:hover & {
    transform: translateY(-4rem);
    transition: transform 0.3s ease-in-out;
  }

  ${breakpoint('xs', 'sm')`
    .swiper-slide-active ${SliderItemWrapper} & {
      transform: translateY(-4rem);
    }
  `};
`;

const ItemTitle = styled.h3`
  padding-bottom: 1.5rem;
  font-size: ${FONT_SIZE_XLARGE};
  line-height: 1.04;
  text-shadow: 2px 4px 4px rgba(0, 0, 0, 0.25);
`;

const ContentInfoLink = styled.button`
  padding: 0;
  position: absolute;
  left: 2rem;
  opacity: 0;
  color: ${COLOR_WHITE};
  font-size: ${FONT_SIZE_XXSMALL};
  font-weight: 300;
  letter-spacing: 0.065em;
  text-transform: uppercase;
  transition: all 0.2s ease-in-out;
  cursor: pointer;

  ${SliderItemWrapper}:hover & {
    left: 0;
    opacity: 1;
    transition: all 0.2s 0.2s ease-in-out;
  }

  ${breakpoint('xs', 'sm')`
    .swiper-slide-active ${SliderItemWrapper} & {
      left: 0;
      opacity: 1;
      transition: all 0.2s 0.2s ease-in-out;
    }
  `};

  &:before {
    content: '';
    display: block;
    height: 7px;
    width: 28px;
    position: absolute;
    left: -4rem;
    top: 5px;
    background-color: ${COLOR_GREEN};
  }
`;

const WorksSliderItem: React.SFC<WorksSliderItemPropsType> = ({
  index,
  id,
  openModal,
  worksItem: { title, subtitle, image },
}: WorksSliderItemPropsType) => {
  const slideNumber = index + 1;
  const properSlideNumber = slideNumber < 10 ? `0${slideNumber}` : slideNumber;

  return (
    <SliderItemWrapper>
      <ImgWrapper>
        <Img fluid={image.fluid} />
      </ImgWrapper>
      <ImgBlurWrapper>
        <Img fluid={image.fluid} />
      </ImgBlurWrapper>

      <SliderItemContent onClick={() => openModal(id)}>
        <ItemNumber>{properSlideNumber}</ItemNumber>

        <ItemContentInfo>
          <p>{subtitle}</p>
          <ItemTitle>{title}</ItemTitle>

          <ContentInfoLink>watch video</ContentInfoLink>
        </ItemContentInfo>
      </SliderItemContent>
    </SliderItemWrapper>
  );
};

export default WorksSliderItem;
