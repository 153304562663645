import { Link } from 'gatsby';
import styled from 'styled-components';
import {
  COLOR_GREY,
  COLOR_WHITE,
  EASE_IN_OUT_CUBIC,
  FONT_SIZE_XSMALL,
  ZINDEX_TWO,
} from '../../styles/Variables';

const NavigationLanguageLink = styled(Link)`
  text-transform: uppercase;
  z-index: ${ZINDEX_TWO};
  font-size: ${FONT_SIZE_XSMALL};
  padding-right: 25px;
  transition: 0.2s ${EASE_IN_OUT_CUBIC};
  color: ${({ activeMenu }: { activeMenu: boolean }) =>
    activeMenu ? COLOR_GREY : COLOR_WHITE};
`;

NavigationLanguageLink.displayName = 'NavigationLanguageLink';

export default NavigationLanguageLink;
