import React from 'react';
import Fade from 'react-reveal/Fade';
import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';

import SectionTitle from '../Base/SectionTitle';

import { COLOR_GREEN, COLOR_GREY_LIGHT } from '../../styles/Variables';
import { WorksTopPropsType } from './types';

const WorksTopWrapper = styled.div`
  padding: 0 8% 2.5rem;

  ${breakpoint('xs', 'md')`
    padding: 0 2rem 4.5rem;
  `}
`;

const SectionTitleWrapper = styled(SectionTitle)`
  padding-bottom: 4.5rem;
  text-align: right;

  ${breakpoint('xs', 'md')`
    padding-bottom: 3rem;
    text-align: left;
  `}
`;

const WorksTopSubtitle = styled.h5`
  padding-top: 1.3rem;
  color: ${COLOR_GREY_LIGHT};
  font-weight: 300;
`;

const WorksTop: React.SFC<WorksTopPropsType> = ({
  worksData: { title, sectionTitle, subtitle },
}) => (
  <WorksTopWrapper>
    <Fade>
      <SectionTitleWrapper
        sectionTitle={sectionTitle}
        options={{
          letters: [{ indexes: [1, 2], color: `${COLOR_GREEN}` }],
        }}
      />
    </Fade>

    <Fade left={true} distance="50px" cascade={true}>
      <h3>{title}</h3>
      <WorksTopSubtitle>{subtitle}</WorksTopSubtitle>
    </Fade>
  </WorksTopWrapper>
);

export default WorksTop;
