import { graphql, Link, useStaticQuery } from 'gatsby';
import compact from 'lodash/compact';
import get from 'lodash/get';
import map from 'lodash/map';
import React, { useState } from 'react';
import Fade from 'react-reveal/Fade';
import styled from 'styled-components';

import config from '../../../config/constants';
import NavigationButton from './NavigationButton';
import NavigationLanguageLink from './NavigationLanguageLink';
import NavigationMenu from './NavigationMenu';
import { NavigationPropsType } from './types';
import { getParams } from './utils';

const NavigationWrapper = styled.div`
  display: flex;
  align-items: center;

  & .react-reveal {
    position: relative;
    z-index: 100;
  }
`;

const languagesQuery = graphql`
  query LanguagesQuery {
    allContentfulHome {
      nodes {
        node_locale
      }
    }
  }
`;

const prepareLanguageLinks = (languages, activePath, activeMenu) =>
  compact(
    map(languages, (language, i) => {
      const path = `/${language === config.defaultLanguage ? '' : language}`;
      const active = path === activePath;
      return (
        !active && (
          <NavigationLanguageLink activeMenu={activeMenu} key={i} to={path}>
            {language}
          </NavigationLanguageLink>
        )
      );
    })
  );

const Navigation: React.SFC<NavigationPropsType> = ({
  location,
  menuItems,
  socialItems,
}: NavigationPropsType) => {
  const [active, toggleActive] = useState(false);
  const pathname = get(location, 'pathname');
  const languages = map(
    get(useStaticQuery(languagesQuery), 'allContentfulHome.nodes', []),
    ({ node_locale }) => node_locale.slice(0, 2)
  );
  return (
    <NavigationWrapper>
      <Fade delay={900}>
        {prepareLanguageLinks(languages, pathname, active)}
        <NavigationButton toggleActive={toggleActive} active={active} />
      </Fade>
      <NavigationMenu
        pathname={pathname}
        active={active}
        menuItems={menuItems}
        socialItems={socialItems}
        toggleActive={toggleActive}
      />
    </NavigationWrapper>
  );
};

export default Navigation;
