import React from 'react';
import styled, { keyframes } from 'styled-components';

import Sprite from '../../assets/img/nature-sprite-2.png';
import ButtonArrowSvg from '../../assets/img/svg/button-arrow.svg';

import { COLOR_GREEN, COLOR_WHITE } from '../../styles/Variables';

export interface ButtonPropsType {
  buttonLink?: string;
  buttonText: string;
  isLink?: boolean;
  reversed?: boolean;
  modalHandler?: () => void;
}

const ani = keyframes`
    0% {
      background-position: 0 40%;
    }
    100% {
      background-position: 100% 40%;
    }
`;

const ani2 = keyframes`
    0% {
      background-position: 100% 40%;
    }

    100% {
      background-position: 0 40%;
    }
`;

const ButtonLinkWrapper = styled.a`
  display: flex;
  justify-content: center;
  flex-direction: ${({ reversed }: { reversed: boolean }) =>
    reversed ? 'row-reverse' : 'row'};
  padding: 1.5rem 3rem;
  position: relative;
  overflow: hidden;
  border: 2px solid ${COLOR_GREEN};
  border-radius: 2.5rem;
  font-size: 1.8rem;
  line-height: 2.2rem;
  color: ${COLOR_GREEN};
  background-color: transparent;
  outline: 0;
  text-align: center;
  transition: all 0.3s ease-in;
  cursor: pointer;

  background-image: url('${Sprite}');
	background-repeat: no-repeat;
	background-size: 7100% 100%;
	-webkit-animation: ${ani} 0.4s steps(70) forwards;
  animation: ${ani} 0.4s steps(70) forwards;
  transition: color .3s ease;

  &:hover {
    color: ${COLOR_WHITE};
    -webkit-animation: ${ani2} 0.4s steps(70) forwards;
    animation: ${ani2} 0.4s steps(70) forwards;
    transition: 0.2s color .3s ease;

    & svg {
      transform: ${({ reversed }: { reversed: boolean }) =>
        reversed ? 'rotate(180deg) translateX(0.6rem)' : 'translateX(0.6rem)'};

      & path {
        fill: ${COLOR_WHITE};
      }
    }
  }

  & svg {
    margin-top: 0.4rem;
    /* TODO: you can export this type outside */
    margin-left: ${({ reversed }: { reversed: boolean }) =>
      reversed ? '0' : '1rem'};
    margin-right: ${({ reversed }: { reversed: boolean }) =>
      reversed ? '1rem' : '0'};
    transform: ${({ reversed }: { reversed: boolean }) =>
      reversed ? 'rotate(180deg)' : 'rotate(0deg)'};
    transition: transform 0.3s ease-in-out;

    & path {
      transition: fill 0.3s ease-in;
    }
  }
`;

const ButtonWrapper = ButtonLinkWrapper.withComponent('button');

const Button: React.SFC<ButtonPropsType> = ({
  isLink = false,
  reversed = false,
  buttonLink,
  buttonText,
  modalHandler,
}: ButtonPropsType) =>
  isLink ? (
    <ButtonLinkWrapper
      type="button"
      target="_blank"
      href={buttonLink}
      reversed={reversed}
    >
      {buttonText}
      <ButtonArrowSvg />
    </ButtonLinkWrapper>
  ) : (
    <ButtonWrapper
      target="_blank"
      href={buttonLink}
      onClick={modalHandler}
      reversed={reversed}
    >
      {/* TODO: if buttonText is always inside you don't need to pass it inside, you can use children instead
        if children or buttonText will be always inside, use condition only for component const Component = ... or ...
    */}
      {buttonText}
      <ButtonArrowSvg />
    </ButtonWrapper>
  );

export default Button;
