import { interpolate } from 'flubber';
import React from 'react';
import styled, { css } from 'styled-components';
import Elipse from '../../assets/img/svg/elipse.svg';
import {
  COLOR_WHITE,
  EASE_IN_OUT_BACK,
  EASE_IN_OUT_QUAD,
  ZINDEX_TWO,
} from '../../styles/Variables';
import { NavigationButtonPropsType } from './types';

const StyledElipse = styled(Elipse)`
  transition: transform 0.6s ${EASE_IN_OUT_BACK};
  ${({ active }) => active && 'transform: rotate(180deg)'};

  :hover {
    cursor: pointer;
  }
`;

const NavigationButtonWrapper = styled.div`
  display: block;
  position: relative;
  z-index: ${ZINDEX_TWO};
`;

const NavigationButtonIcon = styled.i`
  width: 40px;
  height: 40px;
  position: absolute;
  display: block;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  pointer-events: none;
`;

const NavigationButtonIconBurgerActiveStyles = css`
  width: 0%;
  &:nth-child(1) {
    transition-delay: 0s;
  }
  &:nth-child(2) {
    transition-delay: 0.075s;
  }
  &:nth-child(3) {
    transition-delay: 0.15s;
  }
`;

const NavigationButtonIconBurger = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  span {
    background-color: ${COLOR_WHITE};
    display: block;
    width: 28px;
    height: 2px;
    position: relative;
    top: 6px;
    left: 6px;
    margin-top: 6px;
    transition: 0.15s ${EASE_IN_OUT_QUAD};

    &:nth-child(1) {
      transition-delay: 0.3s;
    }
    &:nth-child(2) {
      transition-delay: 0.425s;
    }
    &:nth-child(3) {
      transition-delay: 0.55s;
    }

    ${({ active }: { active: boolean }) =>
      active && NavigationButtonIconBurgerActiveStyles}
  }
`;

const NavigationButtonIconCrossActiveStyles = css`
  &:nth-child(1) {
    height: 80%;
    transition-delay: 0.425s;
  }
  &:nth-child(2) {
    width: 80%;
    transition-delay: 0.275s;
  }
`;

const NavigationButtonIconCross = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  transform: rotate(45deg);
  span {
    transition: 0.15s ${EASE_IN_OUT_QUAD};
    background-color: ${COLOR_WHITE};
    display: block;
    &:nth-child(1) {
      height: 0%;
      width: 2px;
      position: absolute;
      top: 10%;
      left: 18px;
      transition-delay: 0s;
    }
    &:nth-child(2) {
      width: 0%;
      height: 2px;
      position: absolute;
      left: 10%;
      top: 18px;
      transition-delay: 0.15s;
    }

    ${({ active }: { active: boolean }) =>
      active && NavigationButtonIconCrossActiveStyles}
  }
`;

const SvgGroup = styled.g`
  transition: 0.3s;
  transform: ${({ active }: { active: boolean }) =>
    `translate(${active ? -12 : 0}px,${active ? -5 : 0}px)`};

  :hover {
    cursor: pointer;
  }
`;

const Svg = styled.svg`
  margin-right: -23px;
  margin-bottom: -22px;
  margin-top: -5px;
`;

const NavigationButton: React.SFC<NavigationButtonPropsType> = ({
  active,
  toggleActive,
}: NavigationButtonPropsType) => (
  <NavigationButtonWrapper>
    <StyledElipse onClick={() => toggleActive(!active)} active={active} />
    <NavigationButtonIcon>
      <NavigationButtonIconBurger active={active}>
        <span />
        <span />
        <span />
      </NavigationButtonIconBurger>
      <NavigationButtonIconCross active={active}>
        <span />
        <span />
      </NavigationButtonIconCross>
    </NavigationButtonIcon>
  </NavigationButtonWrapper>
);

export default NavigationButton;
