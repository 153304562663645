import { filter, get, keys, map } from 'lodash';
import React, { ReactElement } from 'react';

export const withLanguageFilter = props => {
  const { nodeLocale, data } = props;
  const newProps = {};
  const uniqueKeys = keys(data);
  uniqueKeys.forEach(uniqueKey => {
    const newUniqueKey = uniqueKey.replace('allContentful', 'contentful');
    const filteredData = map(
      filter(
        data[uniqueKey].edges,
        edge => get(edge, 'node.node_locale') === nodeLocale
      ),
      edge => edge.node
    );
    newProps[newUniqueKey] =
      filteredData.length > 1 ? filteredData : filteredData[0];
  });
  return nodeLocale ? newProps : props;
};
