import React, { useState } from 'react';
import Fade from 'react-reveal/Fade';
import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';

import { COLOR_BLACK, FONT_SIZE_LARGE } from '../../styles/Variables';

import { AboutUsBottomPropsType } from './types';

const AboutBottomWrapper = styled.div`
  margin-top: 9rem;
  position: relative;

  ::after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 10%;
    background: -moz-linear-gradient(
      top,
      ${COLOR_BLACK} 0%,
      rgba(0, 0, 0, 0) 100%
    ); /* FF3.6-15 */
    background: -webkit-linear-gradient(
      top,
      ${COLOR_BLACK} 0%,
      rgba(0, 0, 0, 0) 100%
    ); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(
      to bottom,
      ${COLOR_BLACK} 0%,
      rgba(0, 0, 0, 0) 100%
    ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  }

  ${breakpoint('xs', 'lg')`
    display: none;
  `}
`;

const Lead = styled.p`
  position: absolute;
  left: 12rem;
  bottom: 12.5rem;
  font-size: ${FONT_SIZE_LARGE};
`;

function getOffsetTop(elem) {
  let offsetTop = 0;
  let newElem = elem;
  do {
    newElem = newElem.offsetParent;
    if (newElem && !isNaN(newElem.offsetTop)) {
      offsetTop += newElem.offsetTop;
    }
  } while (newElem && newElem.offsetParent);
  return offsetTop;
}

class AboutUsBottom extends React.Component<AboutUsBottomPropsType> {
  public videoRef;

  public startVideo() {
    if (this.videoRef) {
      this.videoRef.play();
    }
  }

  public render() {
    const { lead, video } = this.props;

    return (
      <AboutBottomWrapper>
        <Fade onReveal={() => this.startVideo()}>
          <video
            src={video.url}
            preload="auto"
            ref={ref => (this.videoRef = ref)}
            muted={true}
            width="100%"
            height="100%"
          />
        </Fade>
        <Fade left={true} distance="50px">
          <Lead>{lead}</Lead>
        </Fade>
      </AboutBottomWrapper>
    );
  }
}

export default AboutUsBottom;
