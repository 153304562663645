import React from 'react';
import Fade from 'react-reveal/Fade';
import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';
import Grid from 'styled-components-grid';

import Button from '../Base/Button';

import {
  FONT_SIZE_LARGE,
  FONT_SIZE_MEDIUM,
  GRID_COLUMNS,
} from '../../styles/Variables';
import { ContactBottomPropsType } from './types';

const ContactBottomWrapper = styled(Grid)`
  padding: 8rem 0 4rem;
`;

const ContactBottomLeftWrapper = styled(Grid.Unit)`
  padding-left: 8%;

  ${breakpoint('xs', 'md')`
    padding-left: 0;
  `}
`;

const ContactBottomRightWrapper = styled(Grid.Unit)`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0 calc(1 / 12 * 100%);

  ${breakpoint('xs', 'md')`
    padding: 0;
  `}

  ${breakpoint('xs', 'sm')`
    padding-top: 4.5rem;
  `}

  & h4 {
    padding-bottom: 0.5rem;
  }
`;

const Lead = styled.p`
  font-size: ${FONT_SIZE_LARGE};

  ${breakpoint('sm', 'lg')`
    font-size: 30px;
  `}

  ${breakpoint('xs', 'sm')`
    font-size: ${FONT_SIZE_MEDIUM};
  `}
`;

const ContactBottom: React.SFC<ContactBottomPropsType> = ({
  contactData: { lead, buttonLink, buttonText },
}: ContactBottomPropsType) => (
  <ContactBottomWrapper>
    <ContactBottomLeftWrapper
      size={{ xs: 1, sm: 7 / GRID_COLUMNS, md: 6 / GRID_COLUMNS }}
    >
      <Fade>
        <Lead>{lead.lead}</Lead>
      </Fade>
    </ContactBottomLeftWrapper>
    <ContactBottomRightWrapper
      size={{ xs: 1, sm: 5 / GRID_COLUMNS, md: 6 / GRID_COLUMNS }}
    >
      <Fade top={true} distance="20px">
        <Button buttonLink={buttonLink} buttonText={buttonText} isLink={true} />
      </Fade>
    </ContactBottomRightWrapper>
  </ContactBottomWrapper>
);
export default ContactBottom;
