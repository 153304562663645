import map from 'lodash/map';
import React from 'react';
import styled, { css } from 'styled-components';
import breakpoint from 'styled-components-breakpoint';

import FacebookSvg from '../../assets/img/svg/facebook.svg';
import InstagramSvg from '../../assets/img/svg/instagram.svg';
import LinkedinSvg from '../../assets/img/svg/linkedin.svg';
import MediumSvg from '../../assets/img/svg/medium.svg';
import VimeoSvg from '../../assets/img/svg/vimeo.svg';
import YoutubeSvg from '../../assets/img/svg/youtube.svg';

import {
  COLOR_GREY,
  COLOR_GREY_LIGHT,
  COLOR_WHITE,
  EASE_IN_OUT_BACK,
  EASE_IN_OUT_CUBIC,
  FONT_SIZE_XSMALL,
} from '../../styles/Variables';
import { NavigationMenuFooterPropsType } from './types';

const socialComponents = {
  mail: ({ link }) => link.replace('mailto:', ''),
  youtube: YoutubeSvg,
  medium: MediumSvg,
  instagram: InstagramSvg,
  facebook: FacebookSvg,
  vimeo: VimeoSvg,
  linkedin: LinkedinSvg,
};

const NavigationMenuFooterLink = styled.a`
  color: ${COLOR_GREY_LIGHT};
  margin-left: 38px;
  transition: 0.2s ${EASE_IN_OUT_CUBIC};
  font-size: ${FONT_SIZE_XSMALL};
  svg,
  g,
  path {
    transition: 0.2s ${EASE_IN_OUT_CUBIC};
    fill: ${COLOR_GREY_LIGHT};
    fill-opacity: 1;
  }

  ${breakpoint('xs', 'sm')`
    margin-left: 15px;
  `}

  :first-child {
    ${breakpoint('xs', 'lg')`
      display: none;
    `}
  }

  :hover {
    color: ${COLOR_GREY};
    svg,
    g,
    path {
      fill: ${COLOR_GREY};
    }
  }
`;

const NavigationMenuFooterWrapper = styled.div`
  position: absolute;
  right: 0;
  bottom: -60px;
  transition: 0.2s ${EASE_IN_OUT_CUBIC};
  opacity: 0;
  ${({ activeMenu }: { activeMenu: boolean }) =>
    activeMenu &&
    css`
      opacity: 1;
      transition-delay: 0.5s;
    `};

  ${breakpoint('xs', 'lg')`
    bottom: 0;
  `}
`;

const NavigationMenuFooter: React.SFC<NavigationMenuFooterPropsType> = ({
  socialItems,
  activeMenu,
}: NavigationMenuFooterPropsType) => (
  <NavigationMenuFooterWrapper activeMenu={activeMenu}>
    {map(socialItems, ({ linkId, link }, i) => {
      const Component = socialComponents[linkId];
      return (
        <NavigationMenuFooterLink key={i} target="_blank" href={link}>
          <Component link={link} />
        </NavigationMenuFooterLink>
      );
    })}
  </NavigationMenuFooterWrapper>
);

export default NavigationMenuFooter;
