import kebabCase from 'lodash/kebabCase';
import React, { useRef } from 'react';
import styled, { css } from 'styled-components';
import breakpoint from 'styled-components-breakpoint';

import get from 'lodash/get';
import MenuArrowSvg from '../../assets/img/svg/menu-arrow.svg';
import {
  COLOR_GREY,
  EASE_IN_OUT_CUBIC,
  FONT_SIZE_XXXLARGE,
} from '../../styles/Variables';
import { NavigationMenuItemPropsType } from './types';

const NavigationMenuItemTextSvg = styled.text`
  font-size: ${FONT_SIZE_XXXLARGE};
  font-style: normal;
  font-weight: 900;
  fill: ${COLOR_GREY};
  fill-opacity: 1;
  stroke: ${COLOR_GREY};
  stroke-width: 2px;
  stroke-linecap: butt;
  stroke-linejoin: miter;
  stroke-opacity: 1;

  ${breakpoint('sm', 'xl')`
    font-size: 70px
  `}

  ${breakpoint('xsm', 'sm')`
    font-size: 60px
  `}

  ${breakpoint('xs', 'xsm')`
    font-size: 45px;
  `}

  @media screen and (max-height: 800px) and (min-width: 1023px) {
    font-size: 60px;
  }
`;

const MenuArrowSvgStyled = styled(MenuArrowSvg)`
  opacity: ${({ active }) => (active ? 1 : 0)};
`;

const NavigationMenuItemWrapper = styled.div`
  display: flex;
  flex-direction: row-reverse;
  opacity: 0;
  transform: translateX(-100px);
  transition: 0.3s ${EASE_IN_OUT_CUBIC};

  ${({ active, index }) =>
    active &&
    css`
      transform: translateX(0);
      opacity: 1;
      transition-delay: ${0.3 + index * 0.05}s;
    `}
`;
const NavigationMenuItemLink = styled.a`
  :hover {
    ${NavigationMenuItemTextSvg} {
      fill: none;
    }
  }
`;

const NavigationMenuItemSvg = styled.svg`
  display: block;
  height: 92px;
  width: 500px;

  ${breakpoint('xsm', 'sm')`
    height: 80px;
  `}

  ${breakpoint('xs', 'xsm')`
    height: 60px;
  `}

  @media screen and (max-height: 800px) and (min-width: 1023px) {
    height: 70px;
  }
`;

const NavigationMenuItem: React.SFC<NavigationMenuItemPropsType> = ({
  title,
  hoverHandler,
  hoverIndex,
  activeIndex,
  activeMenu,
  pathname,
  toggleActive,
}: NavigationMenuItemPropsType) => {
  const svgTextElement = useRef(null);
  const svgElement = useRef(null);
  const arrowXTranslation =
    get(svgElement, 'current.clientWidth', 0) -
    get(svgTextElement, 'current.textLength.baseVal.value', 300) -
    50;
  return (
    <NavigationMenuItemWrapper active={activeMenu} index={hoverIndex}>
      <NavigationMenuItemSvg ref={svgElement}>
        <g>
          <MenuArrowSvgStyled
            x={arrowXTranslation}
            textAnchor="end"
            preserveAspectRatio="xMaxYMin meet"
            active={hoverIndex === activeIndex}
          />
          <NavigationMenuItemLink
            onClick={() => toggleActive(false)}
            onMouseEnter={() => hoverHandler(hoverIndex)}
            onMouseLeave={() => hoverHandler()}
            href={`${pathname}#${kebabCase(title)}`}
          >
            <NavigationMenuItemTextSvg
              ref={svgTextElement}
              alignmentBaseline="middle"
              textAnchor="end"
              x="100%"
              y="50%"
            >
              {title}
            </NavigationMenuItemTextSvg>
          </NavigationMenuItemLink>
        </g>
      </NavigationMenuItemSvg>
    </NavigationMenuItemWrapper>
  );
};

export default NavigationMenuItem;
