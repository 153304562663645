import { Link } from 'gatsby';
import React from 'react';
import Fade from 'react-reveal/Fade';
import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';

import config from '../../../config/constants';
import LogoSvg from '../../assets/img/svg/logo.svg';

const StyledLink = styled(Link)`
  width: 132px;

  ${breakpoint('xs', 'sm')`
    width: 98px;
  `}
`;
const Logo: React.SFC<{ language: string }> = ({ language }) => (
  <StyledLink to={`/${language === config.defaultLanguage ? '' : language}`}>
    <Fade delay={900}>
      <LogoSvg />
    </Fade>
  </StyledLink>
);

export default Logo;
