import { graphql, StaticQuery } from 'gatsby';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import React from 'react';
import Helmet from 'react-helmet';

import BrandonBlack from '../../assets/fonts/BrandonText-Black.otf';
import BrandonBold from '../../assets/fonts/BrandonText-Bold.otf';
import BrandonMedium from '../../assets/fonts/BrandonText-Medium.otf';
import BrandonRegular from '../../assets/fonts/BrandonText-Regular.otf';
import { withLanguageFilter } from '../../enhancers/withLanguage';
import { SEOPropsType } from './types';

const SEO: React.SFC<SEOPropsType> = ({ nodeLocale }) => {
  return (
    <StaticQuery
      query={detailsQuery}
      render={data => {
        const { title, description, image } = get(
          withLanguageFilter({ nodeLocale, data }),
          'contentfulSeo',
          {}
        );

        return (
          <Helmet
            htmlAttributes={{
              lang: nodeLocale,
            }}
            title={title}
            titleTemplate={title}
            meta={[
              {
                name: `description`,
                content: description,
              },
              {
                property: `og:title`,
                content: title,
              },
              {
                property: `og:description`,
                content: description,
              },
              {
                property: `og:type`,
                content: `website`,
              },
              {
                property: `og:locale`,
                content: nodeLocale,
              },
              {
                property: `og:image`,
                content: get(image, 'fluid.src', ''),
              },
            ]}
          >
            <link
              rel="preload"
              as="font"
              href={BrandonMedium}
              type="font/otf"
              crossOrigin="anonymous"
            />
            <link
              rel="preload"
              as="font"
              href={BrandonRegular}
              type="font/otf"
              crossOrigin="anonymous"
            />
            <link
              rel="preload"
              as="font"
              href={BrandonBold}
              type="font/otf"
              crossOrigin="anonymous"
            />
            <link
              rel="preload"
              as="font"
              href={BrandonBlack}
              type="font/otf"
              crossOrigin="anonymous"
            />
            <link
              rel="stylesheet"
              href="https://cdnjs.cloudflare.com/ajax/libs/Swiper/4.5.0/css/swiper.min.css"
            />
          </Helmet>
        );
      }}
    />
  );
};

export default SEO;

const detailsQuery = graphql`
  query SEOQuery {
    allContentfulSeo {
      edges {
        node {
          image {
            fluid(maxWidth: 380, quality: 100) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
          description
          node_locale
          title
        }
      }
    }
  }
`;
