import get from 'lodash/get';
import React from 'react';
import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';
import MenuArrowSvg from '../../assets/img/svg/menu-arrow.svg';

import { AboutUsItemPersonPropsType } from './types';

interface OddType {
  odd: boolean;
}

const AboutItemPersonWrapper = styled.div`
  padding-top: 6rem;
  padding-left: calc(2 / 7 * 100%);
  text-align: ${({ odd }: OddType) => (odd ? 'left' : 'right')};

  ${breakpoint('sm', 'lg')`
    padding-left: ${({ odd }: OddType) =>
      odd ? 'calc(2 / 7 * 100%)' : '3rem'};
  `}

  ${breakpoint('xs', 'sm')`
    padding-top: 5rem;
    padding-left: 2rem;
    padding-right: ${({ odd }: OddType) => (odd ? '0' : '2rem')};
  `}
`;

const PersonRole = styled.h6`
  padding: 1.5rem 0 2rem;
`;

const PersonTitle = styled.div`
  display: flex;
  justify-content: ${({ odd }: OddType) => (odd ? 'flex-start' : 'flex-end')};
  padding-top: ${({ odd }: OddType) => (odd ? '0' : '12rem')};

  ${breakpoint('xs', 'sm')`
    padding-top: 0;
  `}

  & svg {
    margin-right: 1.5rem;
    transform: ${({ odd }: OddType) =>
      odd ? 'rotate(180deg)' : 'rotate(0deg)'};

    & path,
    rect {
      fill: ${({ arrowColor }: { arrowColor: string }) => arrowColor};
    }
  }
`;

const AboutUsItemPerson: React.SFC<AboutUsItemPersonPropsType> = ({
  item,
  arrowColor,
  odd = false,
}: AboutUsItemPersonPropsType) => (
  <AboutItemPersonWrapper odd={odd}>
    <PersonTitle arrowColor={arrowColor} odd={odd}>
      <MenuArrowSvg textAnchor="end" preserveAspectRatio="xMaxYMin meet" />
      <h3>{get(item, 'name', '')}</h3>
    </PersonTitle>

    <PersonRole>{get(item, 'role', '')}</PersonRole>
    <p>{get(item, 'description.description', '')}</p>
  </AboutItemPersonWrapper>
);

export default AboutUsItemPerson;
